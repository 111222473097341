import Icons from "../constants/Icons";
import Images from "../constants/Images";

const Features = () => {
  let featuresList = [
    {
      id: 1,
      title: "Easy & Convenient",
      body: "Apply for Trainings, track your applications, check your postings, results and much more online.",
    },
    {
      id: 2,
      title: "Safe & Secure",
      body: "All user interactions & transactions are secured using industry best standards.",
    },
    {
      id: 3,
      title: "Robust & Flexible",
      body: "Manage training processes, access e-Payment options, use on any device (PC, Tablet, Mobile)",
    },
  ];

  return (
    <section class="event-section">
      <div class="auto-container">
        <div class="row clearfix">
          <div class="content-column col-lg-6 col-md-12 col-sm-12">
            {featuresList.map((x) => (
              <div class="inner-column">
                <div class="sec-title">
                  <div class="title">
                    <img src={Icons.love} />
                  </div>
                  <h5>{x.title}</h5>
                </div>
                {x.body}
                <br />
                <br />
              </div>
            ))}
          </div>

          <div class="images-column col-lg-6 col-md-12 col-sm-12">
            <div
              class="image wow fadeInRight animated"
              data-wow-delay="0ms"
              data-wow-duration="1500ms"
            >
              <img src={Images.macbook_half} alt="" />
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default Features;
