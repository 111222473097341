import { Outlet, Link } from "react-router-dom";
import Footer from "../Footer";

const AccountLayout = () => {   

    return (
        <div class="page-wrapper">
        <Outlet/>
        <Footer/>
    </div>
    );
}

export default AccountLayout;