const Privacy = () => {
  return (
    <>
      <div className="content container-fluid pb-5 px-lg-5">
        <div className="body" style={{ paddingTop: "200px" }}>
          <div className="card-body">
            <h3 className="my-4" style={{ color: "black" }}>
              NAPTIN PRIVACY POLICY
            </h3>
            <p>
              NAPTIN is committed to protecting the privacy of its web site
              users, and preventing unauthorized use of the information it
              collects. NAPTIN does not sell, trade, rent, otherwise distribute
              the information collected to others. The demographic information
              collected is used to provide you with a more personalized
              experience. We also monitor user traffic patterns and site usage
              to help us develop a web site that serves our stakeholder'
              business needs. NAPTIN may use the information collected to
              provide notifications about important functionality changes to our
              web site and new service.
            </p>
          </div>
        </div>
      </div>
    </>
  );
};
export default Privacy;
