/*
import logo from './logo.svg';
import './App.css';

function App() {
  return (
    <div className="App">
      <header className="App-header">
        <img src={logo} className="App-logo" alt="logo" />
        <p>
          Edit <code>src/App.js</code> and save to reload.
        </p>
        <a
          className="App-link"
          href="https://reactjs.org"
          target="_blank"
          rel="noopener noreferrer"
        >
          Learn React
        </a>
      </header>
    </div>
  );
}

export default App;
*/

import "./App.css";
import MainLayout from "./components/MainLayout";
import Home from "./components/Home";
import { BrowserRouter, Routes, Route } from "react-router-dom";
import PageNotFound from "./components/PageNotFound";
import Ngsdp from "./components/programmes/Ngsdp";
import Ntsap from "./components/programmes/Ntsap";
import EventsNews from "./components/events/EventsNews";
import ViewPost from "./components/events/ViewPost";
import Faq from "./components/supports/Faq";
import SupportAndHelp from "./components/supports/SupportAndHelp";
import TechnicalCourses from "./components/courses/TechnicalCourses";
import NonTechnicalCourses from "./components/courses/NonTechnicalCourses";
import ProgrammesAndCourses from "./components/courses/ProgrammesAndCourses";
import CourseDetails from "./components/courses/CourseDetails";
import Login from "./components/account/Login";
import AccountLayout from "./components/account/AccountLayout";
import Privacy from "./components/privacy";
import SignUp from "./components/account/SignUp";
import ForgetPassword from "./components/account/forgetPassword";
import TraineeMainLayout from "./components/trainee-dashboard/Layouts/TraineeMainLayout";
import TraineeDashbaord from "./components/trainee-dashboard/Dashboard";
import VerifyEmail from "./components/account/VerifyEmail";
import TrainingCenters from "./components/courses/TrainingCenters";
import LogOut from "./components/account/LogOut";
import Calendar from "./components/calendar/Calendar";
import UpcomingTrainings from "./components/courses/UpcomingTrainings";
import ResetPassword from "./components/account/ResetPassword";
import Programs from "./components/programmes/ViewPrograms";
import ViewProgramCourses from "./components/programmes/ViewProgramCourses";

function App() {
  return (
    <BrowserRouter>
      <Routes>
        {/* Landing page */}
        <Route path="/" element={<MainLayout />}>
          <Route path="programs" element={<Programs />}></Route>
          <Route path="/program-courses/:id" element={<ViewProgramCourses />} />
          <Route index element={<Home />} />
          <Route path="ngsdp" element={<Ngsdp />} />
          <Route path="ntsap" element={<Ntsap />} />
          <Route path="news" element={<EventsNews />} />
          <Route path="faq" element={<Faq />} />
          <Route path="help_support" element={<SupportAndHelp />} />
          <Route path="technical_courses" element={<TechnicalCourses />} />
          <Route
            path="non_technical_courses"
            element={<NonTechnicalCourses />}
          />
          <Route
            path="programmes_and_courses"
            element={<ProgrammesAndCourses />}
          />
          <Route path="course_details/:id" element={<CourseDetails />} />
          <Route path="training-centres" element={<TrainingCenters />} />
          <Route path="calendar" element={<Calendar />} />
          <Route path="upcoming_trainings" element={<UpcomingTrainings />} />
          <Route path="privacy-policy" element={<Privacy />} />
          <Route path=":id/post" element={<ViewPost />} />
          <Route path="*" element={<PageNotFound />} />
        </Route>

        {/* Auth page */}
        <Route path="/" element={<AccountLayout />}>
          <Route path="login" element={<Login />} />
          <Route path="signup" element={<SignUp />} />
          <Route path="forget-password" element={<ForgetPassword />} />
          <Route path="reset-password" element={<ResetPassword />} />
          <Route path="verify-email/:id/:hash" element={<VerifyEmail />} />
          <Route path="logout" element={<LogOut />} />
        </Route>

        {/* Trainee Page */}
        <Route path="/trainee" element={<TraineeMainLayout />}>
          <Route path="/trainee/dashboard" element={<TraineeDashbaord />} />
        </Route>
      </Routes>
    </BrowserRouter>
  );
}

export default App;
