import { ToastContainer, toast } from "react-toastify";
import React, { Component, useState, useEffect } from "react";
import { Link } from "react-router-dom";
import Alert from "../../constants/Alert";
import ErrorMessages from "../../constants/ErrorMessages";
import Spinner from "../../constants/Spinner";
import axios from "axios";
import Urls from "../../constants/Urls";

const Login = () => {
  document.title = "Log In";
  const [email, setEmail] = useState(null);
  const [password, setPassword] = useState(null);
  const [loading, setLoading] = useState(false);
  const [showAlert, setShowAlert] = useState(false);
  const [alertType, setAlertType] = useState(null);
  const [alertMessage, setAlertMessage] = useState(null);
  const [alertTitle, setAlertTitle] = useState(null);

  const accountType = localStorage.getItem("accountType");
  const token = localStorage.getItem("token");

  const onLoginEnter = (event) => {
    if (event.keyCode === 13) {
      loginBtn();
    }
  };

  const loginBtn = async () => {
    localStorage.removeItem("token");
    localStorage.removeItem("accountType");
    //  remove token
    setShowAlert(false);
    setLoading(true);

    const postOptions = {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        Accept: "application/json",
        "Access-Control-Allow-Credentials": true,
      },
      body: JSON.stringify({
        email: email,
        password: password,
      }),
    };
    fetch(Urls.api + "auth/login", postOptions)
      .then((response) => response.json())
      .then((json) => {
        if (json.success === false) {
          setAlertMessage(json.message);
          setAlertTitle("Error!");
          setAlertType("danger");
          setShowAlert(true);
        } else {
          setShowAlert(false);
          setLoading(false);
          localStorage.setItem("token", json.data.auth_token);
          localStorage.setItem("accountType", json.data.roles[0]);

          if (localStorage.getItem("token") !== null) {
            if (json.data.roles[0] == "trainee")
              window.location.replace(
                Urls.traineeUrl + "register?punch=" + json.data.auth_token
              );
            else if (json.data.roles[0] == "trainer")
              window.location.replace(
                Urls.trainerUrl + "register?punch=" + json.data.auth_token
              );
            // company / sponsor
            else if (json.data.roles[0] == "company")
              window.location.replace(
                Urls.sponsorUrl + "register?punch=" + json.data.auth_token
              );
            else
              window.location.replace(
                Urls.traineeUrl + "dashboard?punch=" + json.data.auth_token
              );
          } else {
            window.location.replace("/login");
          }
        }
      })
      .catch((error) => {
        setAlertMessage(ErrorMessages.UnkownRetry);
        setAlertTitle("Error!");
        setAlertType("danger");
        setShowAlert(true);
      })
      .finally(() => setLoading(false));
    // })
  };

  const profileCheck = () => {
    const postOptions = {
      method: "GET",
      headers: {
        Authorization: `Bearer ${token}`,
        Accept: "application/json",
        "Content-Type": "application/json",
        "Access-Control-Allow-Credentials": true,
      },
    };

    fetch(Urls.api + "profile/my-profile", postOptions)
      .then((response) => response.json())
      .then((json) => {
        if (json.success) {
          let user = json.data.user;
          if (!user.bio.verified) {
            if (accountType == "trainee")
              window.location.replace(Urls.traineeUrl + "verify_email");
            else if (accountType == "trainer")
              window.location.replace(Urls.trainerUrl + "verify_email");
            // company / sponsor
            else if (accountType == "company")
              window.location.replace(Urls.sponsorUrl);
            else window.location.replace(Urls.traineeUrl + "verify_email");
          } else if (
            user.kit.shoeSize == null ||
            user.nextOfKin.firstName == null ||
            user.bio.stateOfOrigin == null
          ) {
            if (accountType == "trainee")
              window.location.replace(Urls.traineeUrl + "register");
            else if (accountType == "trainer")
              window.location.replace(Urls.trainerUrl + "register");
            // company / sponsor
            else if (accountType == "company")
              window.location.replace(Urls.sponsorUrl);
            else window.location.replace(Urls.traineeUrl + "register");
          }
        } else if (json.message == "Your email address is not verified.") {
          if (accountType == "trainee")
            window.location.replace(Urls.traineeUrl + "verify_email");
          else if (accountType == "trainer")
            window.location.replace(Urls.trainerUrl + "verify_email");
          // company / sponsor
          else if (accountType == "company")
            window.location.replace(Urls.sponsorUrl);
          else window.location.replace(Urls.traineeUrl + "verify_email");
        } else if (json.message == "Unauthenticated.") {
          /// window.location.href = "/logout"
        }
      })
      .catch((error) => {
        //window.location.href = "/logout"
      })
      .finally(() => console.log("***"));
  };

  useEffect(() => {
    profileCheck();
  }, []);

  return (
    <div className="row">
      <div className="bg-success col-md-12">
        <div class="py-32pt">
          <div class="container d-flex flex-column flex-md-row align-items-center mt-5">
            <i className="fa fa-graduation-cap fa-5x text-white"></i>
            <div class="flex mb-32pt mb-md-0">
              <h4 class="text-white mb-0"> Sign In</h4>
              <p class="lead measure-lead text-white-50">Account Management</p>
            </div>
            {/* <Link to="/signup" class="text-right text-white flex-column">
                Don't have an account?
                <span class="btn__secondary-text">Sign up Today!</span>
            </Link> */}
          </div>
        </div>
      </div>

      <div className="col-md-12">
        <div class="row col-md-5 p-0 mx-auto">
          <div class="container mt-5">
            <div class="form-group">
              <label for="email">Email:</label>
              <input
                value={email}
                onChange={(e) => setEmail(e.target.value)}
                type="email"
                class="form-control"
              />
            </div>
            <div class="form-group">
              <label for="password">Password:</label>
              <input
                value={password}
                onChange={(e) => setPassword(e.target.value)}
                type="password"
                class="form-control"
                onKeyDown={(e) => onLoginEnter(e)}
              />
              <p class="text-right">
                <Link to="/forget-password" class="small">
                  Forgot your password?
                </Link>
              </p>
            </div>
            {showAlert ? (
              <Alert
                type={alertType}
                title={alertTitle}
                message={alertMessage}
              />
            ) : (
              ""
            )}
            <div class="text-center">
              {!loading ? (
                <button
                  onClick={() => loginBtn()}
                  class="btn btn-lg btn-block btn-success"
                >
                  Login
                </button>
              ) : (
                <Spinner />
              )}
              <p class="text-right mt-2">
                <Link to="/signup" class="large">
                  Open New Account!
                </Link>
              </p>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Login;
