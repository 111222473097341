import React, { Component, useEffect, useState } from "react";
import { Link, useParams } from "react-router-dom";
import Images from "../../constants/Images";
import TopHeader from "../TopHeader";
import "../../constants/custom.css";
import Spinner from "../../constants/Spinner";
import Alert from "../../constants/Alert";
import SupportRightSideBar from "./SupportRightSideBar";
import urls from "../../constants/Urls";
import axios from "axios";
import { ToastContainer, toast } from "react-toastify";

const ticketState = {
  email: "",
  title: "",
  priority: "",
  description: "",
};

const SupportAndHelp = () => {
  const { id } = useParams();

  let requestOptions = [
    { id: 2, request: "New Ticket", title: "Raise a Ticket" },
  ];

  const [title, setTitle] = useState("Help & Support");
  const [loading, setLoading] = useState(false);
  const [requestType, setRequestType] = useState(null);
  const [selectedRequestType, setSelectedRequestType] = useState(null);

  const [submitQuestion, setSubmitQuestion] = useState(false);
  const [showAlert, setShowAlert] = useState(false);
  const [alertType, setAlertType] = useState(null);
  const [alertMessage, setAlertMessage] = useState(null);
  const [alertTitle, setAlertTitle] = useState(null);

  const [phone, setPhone] = useState(null);
  const [email, setEmail] = useState(null);
  const [subject, setSubject] = useState(null);
  const [message, setMessage] = useState(null);
  const [fullName, setFullName] = useState(null);

  const submitCourseSuggestion = () => {};

  const [submitticket, setSubmitTicket] = useState(ticketState);
  const headers = {
    Authorization: "Bearer " + localStorage.getItem("token"),
  };

  const submitTicket = (e) => {
    e.preventDefault();
    try {
      axios
        .post(
          urls.api + "support-ticket/create-new-support-ticket",
          submitticket,
          { headers }
        )
        .then((result) => {
          console.log(result.data.success);
          if (result.data.success === true) {
            toast.success("Ticket Created Successfully");
          }
        })
        .catch((innerError) => {
          toast.warning("Invalid credentials, kindly login");
        });
    } catch (error) {
      toast.error("Unable to Create Ticket");
    }
  };

  const trackTicket = (x) => {};

  //suggestion
  const [courseTitle, setCourseTitle] = useState(null);
  const [courseType, setCourseType] = useState(null);
  const [courseDescription, setCourseDescription] = useState(null);

  //new ticket
  const [ticketTitle, setTicketTitle] = useState(null);
  const [ticketCategoryList, setTicketCategoryList] = useState([
    { id: 1, cat: "Low" },
    { id: 2, cat: "Medium" },
    { id: 3, cat: "High" },
  ]);
  const [ticketCategory, setTicketCategory] = useState(null);
  const [ticketDescription, setTicketDescription] = useState(null);

  //ticket track
  const [ticketId, setTicketId] = useState(null);

  useEffect(() => {
    //        setQuestions(postsList)
  }, []);

  const handleChange = (e) => {
    const { name, value } = e.target;
    setSubmitTicket((prevState) => ({ ...prevState, [name]: value }));
  };

  return (
    <div class="auto-container">
      <div className="row mt-5">
        <TopHeader title={title} />
        <ToastContainer pauseOnFocusLoss={false} />

        <div class="container mt-5">
          <div className="row">
            <div class="content-column col-lg-8 cl-md-12 col-sm-12">
              <div className="row">
                <div className="col-md-row text-dark">
                  <div className="col-md-12">
                    Short description of what this page does....
                  </div>

                  <div className="col-md-12 mt-5">
                    Do you want to make a suggestion, raise a ticket or track
                    your ticket? Please, select an option below.
                    <select
                      className="form-control mt-2"
                      onChange={(e) => setSelectedRequestType(e.target.value)}
                    >
                      <option value={null}>Select</option>
                      {requestOptions.map((x) => (
                        <option value={x.request}>{x.title}</option>
                      ))}
                    </select>
                  </div>

                  {selectedRequestType == "Suggestion" ? (
                    <form
                      className="col-md-12 mt-5"
                      onSubmit={submitCourseSuggestion}
                    >
                      <div className="col mt-2">
                        <label>Full Name</label>
                        <input
                          className="form-control"
                          value={submitticket.fullName}
                          onChange={(x) => setFullName(x.target.value)}
                          type={"text"}
                        />
                      </div>
                      <div className="col mt-2">
                        <label>Phone</label>
                        <input
                          className="form-control"
                          value={submitticket.phone}
                          onChange={(x) => setPhone(x.target.value)}
                          type={"tel"}
                        />
                      </div>
                      <div className="col mt-2">
                        <label>Email</label>
                        <input
                          className="form-control"
                          value={submitticket.email}
                          onChange={(x) => setEmail(x.target.value)}
                          type={"email"}
                        />
                      </div>
                      <div className="col mt-2">
                        <label>Course Title</label>
                        <input
                          className="form-control"
                          value={submitticket.courseTitle}
                          onChange={(x) => setCourseTitle(x.target.value)}
                          type={"text"}
                        />
                      </div>
                      <div className="col mt-2">
                        <label>Course Type</label>
                        <input
                          className="form-control"
                          value={submitticket.courseType}
                          onChange={(x) => setCourseType(x.target.value)}
                          type={"text"}
                        />
                      </div>
                      <div className="col mt-2">
                        <label>Description</label>
                        <textarea
                          className="form-control"
                          onChange={(x) => setCourseDescription(x.target.value)}
                        >
                          {courseDescription}
                        </textarea>
                      </div>

                      {showAlert ? (
                        <Alert
                          type={alertType}
                          title={alertTitle}
                          message={alertMessage}
                        />
                      ) : (
                        ""
                      )}

                      <div className="col mt-2">
                        {!loading ? (
                          <button
                            onClick={() => submitCourseSuggestion()}
                            className="btn btn-sm btn-success"
                          >
                            <i className="fa fa-check-circle"></i> Submit
                            Suggestion
                          </button>
                        ) : (
                          <Spinner />
                        )}
                      </div>
                    </form>
                  ) : (
                    ""
                  )}

                  {selectedRequestType == "New Ticket" ? (
                    <form className="col-md-12 mt-5" onSubmit={submitTicket}>
                      {/* <div className='col mt-2'>
                                        <label>Full Name</label>
                                        <input className='form-control' value={submitticket.fullName} onChange={handleChange} type={'text'}/>
                                        </div> */}
                      {/* <div className='col mt-2'>
                                    <label>Phone</label>
                                    <input className='form-control' value={submitticket.phone} onChange={handleChange} type={'tel'}/>
                                    </div> */}
                      <div className="col mt-2">
                        <label>Email</label>
                        <input
                          className="form-control"
                          name="email"
                          value={submitticket.email}
                          onChange={handleChange}
                          type={"email"}
                          required
                        />
                      </div>
                      <div className="col mt-2">
                        <label>Ticket Title</label>
                        <input
                          className="form-control"
                          name="title"
                          value={submitticket.title}
                          onChange={handleChange}
                          type={"text"}
                          required
                        />
                      </div>
                      <div className="col mt-2">
                        <label>Ticket Priority</label>
                        <select
                          className="form-control"
                          name="priority"
                          value={submitticket.priority}
                          onChange={handleChange}
                          required
                        >
                          {ticketCategoryList.map((y) => (
                            <option>{y.cat}</option>
                          ))}
                        </select>
                      </div>
                      <div className="col mt-2">
                        <label>Ticket Description</label>
                        <textarea
                          className="form-control"
                          name="description"
                          value={submitticket.description}
                          onChange={handleChange}
                          required
                        ></textarea>
                      </div>

                      {showAlert ? (
                        <Alert
                          type={alertType}
                          title={alertTitle}
                          message={alertMessage}
                        />
                      ) : (
                        ""
                      )}

                      <div className="col mt-2">
                        {!loading ? (
                          <button
                            className="btn btn-sm btn-success"
                            type="submit"
                          >
                            <i className="fa fa-check-circle"></i> Submit Ticket
                          </button>
                        ) : (
                          <Spinner />
                        )}
                      </div>
                    </form>
                  ) : (
                    ""
                  )}

                  {selectedRequestType == "Track Ticket" ? (
                    <div className="col-md-12 mt-5">
                      <div className="input-group">
                        <input
                          className="form-control"
                          placeholder="Enter Ticket ID"
                          value={submitticket.ticketId}
                          onChange={(x) => setTicketId(x.target.value)}
                          type={"search"}
                        />
                        <div className="input-group-append">
                          {!loading ? (
                            <span
                              onClick={() => trackTicket(ticketId)}
                              className="btn btn-success"
                            >
                              {" "}
                              <i className="fa fa-search"></i> Track Ticket
                            </span>
                          ) : (
                            <Spinner />
                          )}
                        </div>
                      </div>
                    </div>
                  ) : (
                    ""
                  )}
                </div>
              </div>
            </div>

            <SupportRightSideBar />
          </div>
        </div>
      </div>
    </div>
  );
};

export default SupportAndHelp;
