import { useEffect, useState } from "react";
import { Link, useNavigate, useParams } from "react-router-dom";
import Urls from "../../constants/Urls";
import Images from "../../constants/Images";

const ViewProgramCourses = () => {
  const token = localStorage.getItem("token");
  const { id } = useParams();

  const [editProfile, setEditProfile] = useState(false);
  const [loading, setLoading] = useState(false);

  const [courses, setCourses] = useState([]);

  const ProgramCourses = () => {
    setLoading(true);
    const postOptions = {
      method: "GET",
      headers: {
        // Authorization: `Bearer ${token}`,
        Accept: "application/json",
        "Content-Type": "application/json",
        "Access-Control-Allow-Credentials": true,
      },
    };

    fetch(Urls.api + `program/${id}`, postOptions)
      .then((response) => response.json())
      .then((json) => {
        setCourses(json.data.courses);
      })
      .catch((error) => {
        console.log(error);
      })
      .finally(() => setLoading(false));
  };

  useEffect(() => {
    ProgramCourses();
  }, []);
  return (
    <>
      <section
        class="banner-section-four"
        style={{ backgroundColor: "#d5f1f6" }}
      >
        <div class="auto-container">
          <div class="row clearfix">
            <div class="content-column col-lg-6 cl-md-12 col-sm-12">
              <div class="inner-column">
                <h1 className="text-dark">
                  Apply
                  <br />
                  For Programs
                </h1>
                <h5 className="text-dark mt-2">
                  Improving student potentials for rewarding careers in the{" "}
                  <br />
                  power sector.
                </h5>
              </div>
            </div>

            <div class="image-column col-lg-6 cl-md-12 col-sm-12">
              <div class="inner-column">
                <div class="color-layer"></div>
                <div
                  class="icon-layer-four"
                  style={{
                    backgroundImage: `url(/assets/images2/background/pattern-7.png)`,
                  }}
                ></div>
                <div
                  class="icon-layer-five"
                  style={{
                    backgroundImage: `url(/assets/images2/icons/icon-12.png)`,
                  }}
                ></div>
                <div
                  class="icon-layer-six"
                  style={{
                    backgroundImage: `url(/assets/images2/icons/icon-3.png)`,
                  }}
                ></div>
                <div
                  class="icon-layer-seven"
                  style={{
                    backgroundImage: `url(/assets/images2/icons/icon-12.png)`,
                  }}
                ></div>
                <div class="image">
                  <img
                    src={Images.ntsap}
                    style={{ width: `100%`, height: `350px` }}
                    alt=""
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
      <section>
        <div className="row" style={{ width: "100%", marginTop: "100px" }}>
          {courses.length > 0 ? (
            courses.map((x) => (
              <div className="col-sm-12 col-md-5 col-lg-4 my-5 ">
                <div class="card mx-4 my-5">
                  <div class="card-body ">
                    <div class="image">
                      <img
                        src={x.image}
                        alt=""
                        className="co_img"
                        style={{ height: "250px" }}
                      />
                    </div>
                    <div className="mx-2 my-4">
                      <strong style={{ float: "left" }}>Course Title</strong>
                      <p style={{ float: "right" }}>{x.title}</p>
                    </div>
                    <br></br>
                    <hr></hr>
                    <div className="mx-2 my-4">
                      <strong style={{ float: "left" }}>Course Type</strong>
                      <p style={{ float: "right" }}>{x.courseType}</p>
                    </div>
                    <br></br>
                    <hr></hr>
                    <table className="table">
                      <thead>
                        <tr>
                          <th>Description</th>
                        </tr>
                      </thead>
                      <tbody>
                        <tr>
                          <td>
                            <p>${x.description}</p>
                          </td>
                        </tr>
                      </tbody>
                    </table>
                    {/* <div className="mx-2 my-4">
                            <strong style={{ float: "left" }}>Cost</strong>
                            <p style={{ float: "right" }}>{x.cost}</p>
                          </div> */}
                    <br></br>
                    <hr></hr>
                    {/* <div className="mx-2 my-4">
                            <strong style={{ float: "left" }}>
                              Minimun Deposit
                            </strong>
                            <p style={{ float: "right" }}>{x.minDeposit}</p>
                          </div> */}
                    <br></br>
                  </div>
                </div>
              </div>
            ))
          ) : (
            <>
              <div class="card mx-5">
                <div class="card-body ">
                  <h1>No Courses available</h1>
                </div>
              </div>
            </>
          )}
        </div>
      </section>
    </>
  );
};

export default ViewProgramCourses;
