import React, { Component } from "react";
import Images from "../../constants/Images";
import { useEffect } from "react";
import { useState } from "react";
import Urls from "../../constants/Urls";
import { Link, useNavigate } from "react-router-dom";
import DateTimeToDate from "../../DateTimeToDate";

const Programs = () => {
  const token = localStorage.getItem("token");

  const [editProfile, setEditProfile] = useState(false);
  const [loading, setLoading] = useState(false);
  const [response, setResponse] = useState(null);
  const [startDate, setStartDate] = useState("");
  const [endDate, setEndDate] = useState("");
  const [rtc, setRtc] = useState("");
  const [chosenDate, setChosenDate] = useState([]);
  const [calenderUuid, setCalenderUuid] = useState("");
  const [getCourse, setGetCourse] = useState([]);
  const [getCalendar, setGetCalendar] = useState([]);
  const [getRtc, setGetRtc] = useState([]);
  const [duration, setDuration] = useState();
  const [closeDate, setCloseDate] = useState("");
  const [programs, setPrograms] = useState([]);
  const [applyClicked, setApplyClicked] = useState(false);

  // const useStyles = makeStyles((theme) => ({
  //   backdrop: {
  //     zIndex: theme.zIndex.drawer + 1,
  //     color: "#fff",
  //   },
  // }));
  // const classes = useStyles();

  const getPrograms = () => {
    setLoading(true);
    const postOptions = {
      method: "GET",
      headers: {
        //  Authorization: `Bearer ${token}`,
        Accept: "application/json",
        "Content-Type": "application/json",
        "Access-Control-Allow-Credentials": true,
      },
    };

    fetch(Urls.api + "program/all", postOptions)
      .then((response) => response.json())
      .then((json) => {
        setPrograms(json.data);
        console.log(json.data);
      })
      .catch((error) => {
        console.log(error);
      })
      .finally(() => setLoading(false));
  };
  //final fixes

  function findObjectByDate(array, date) {
    for (let i = 0; i < array.length; i++) {
      if (new Date(array[i].startDate).getTime() === date.getTime()) {
        return array[i];
      }
    }
    return null;
  }

  const calculateDuration = (e) => {
    const dateStr = e.target.value;
    const dates = dateStr.split("|");
    const date1 = new Date(dates[0]);
    const date2 = new Date(dates[1]);
    setCalenderUuid(dates[2]);
    setStartDate(new Date(date1).toLocaleDateString("en-US"));
    setEndDate(new Date(date2).toLocaleDateString("en-US"));
    const Difference_In_Time =
      new Date(date2).getTime() - new Date(date1).getTime();
    const Difference_In_Days = Difference_In_Time / (1000 * 3600 * 24);
    setDuration(Difference_In_Days + 1 + " Days");
    const searchDate = new Date(date1);
    const result = findObjectByDate(getCalendar, searchDate);
    setCloseDate(new Date(result.payCloseDate).toLocaleDateString("en-US"));
  };
  const DATE_OPTIONS = {
    weekday: "short",
    year: "numeric",
    month: "short",
    day: "numeric",
  };
  const handle = (e) => {
    setRtc(e.target.value);
    console.log(rtc);
  };

  useEffect(() => {
    getPrograms();
  }, []);
  return (
    <>
      <section
        class="banner-section-four"
        style={{ backgroundColor: "#d5f1f6" }}
      >
        <div class="auto-container">
          <div class="row clearfix">
            <div class="content-column col-lg-6 cl-md-12 col-sm-12">
              <div class="inner-column">
                <h1 className="text-dark">
                  Apply
                  <br />
                  For Programs
                </h1>
                <h5 className="text-dark mt-2">
                  Improving student potentials for rewarding careers in the{" "}
                  <br />
                  power sector.
                </h5>
              </div>
            </div>

            <div class="image-column col-lg-6 cl-md-12 col-sm-12">
              <div class="inner-column">
                <div class="color-layer"></div>
                <div
                  class="icon-layer-four"
                  style={{
                    backgroundImage: `url(/assets/images2/background/pattern-7.png)`,
                  }}
                ></div>
                <div
                  class="icon-layer-five"
                  style={{
                    backgroundImage: `url(/assets/images2/icons/icon-12.png)`,
                  }}
                ></div>
                <div
                  class="icon-layer-six"
                  style={{
                    backgroundImage: `url(/assets/images2/icons/icon-3.png)`,
                  }}
                ></div>
                <div
                  class="icon-layer-seven"
                  style={{
                    backgroundImage: `url(/assets/images2/icons/icon-12.png)`,
                  }}
                ></div>
                <div class="image">
                  <img
                    src={Images.ntsap}
                    style={{ width: `100%`, height: `350px` }}
                    alt=""
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
      <section>
        <div className="row" style={{ width: "100%" }}>
          {programs.length > 0 ? (
            programs.map((x) => (
              <div className="col-sm-12 col-md-6 col-lg-6">
                <div class="card my-5 mx-4">
                  <div class="card-body">
                    <table className="table">
                      <thead>
                        <tr>
                          <th>Date</th>
                        </tr>
                      </thead>
                      <tbody>
                        <tr>
                          <td>
                            {DateTimeToDate.dateTimeToString(x.createdAt)}
                          </td>
                        </tr>
                      </tbody>
                    </table>
                    <table className="table">
                      <thead>
                        <tr>
                          <th>Program Title</th>
                        </tr>
                      </thead>
                      <tbody>
                        <tr>
                          <td>{x.name}</td>
                        </tr>
                      </tbody>
                    </table>
                    <div className="mx-2 my-4">
                      <strong style={{ float: "left" }}>Minimun Deposit</strong>
                      <p style={{ float: "right" }}>{x.minimumDeposit}</p>
                    </div>
                    <br></br>
                    <hr></hr>
                    <div className="mx-2 my-4">
                      <strong style={{ float: "left" }}>
                        Number Of Installment
                      </strong>
                      <p style={{ float: "right" }}>{x.noOfInstallments}</p>
                    </div>
                    <br></br>
                    <div className="mt-3">
                      <table className="table">
                        <thead>
                          <tr>
                            <th>Calender</th>
                          </tr>
                        </thead>
                        <tbody>
                          <tr>
                            <td>
                              <select
                                id="date"
                                class="form-control mx-2"
                                onChange={(e) => {
                                  calculateDuration(e);
                                }}
                              >
                                <option>Select</option>
                                {x.calendars.length > 0 ? (
                                  x.calendars.map((each) => {
                                    return (
                                      <>
                                        {" "}
                                        <option
                                          value={
                                            new Date(
                                              each.startDate
                                            ).toLocaleDateString(
                                              "en-US",
                                              DATE_OPTIONS
                                            ) +
                                            " | " +
                                            new Date(
                                              each.endDate
                                            ).toLocaleDateString(
                                              "en-US",
                                              DATE_OPTIONS
                                            ) +
                                            " | " +
                                            each.uuid
                                          }
                                        >
                                          {new Date(
                                            each.startDate
                                          ).toLocaleDateString(
                                            "en-US",
                                            DATE_OPTIONS
                                          ) +
                                            " - " +
                                            new Date(
                                              each.endDate
                                            ).toLocaleDateString(
                                              "en-US",
                                              DATE_OPTIONS
                                            )}
                                        </option>
                                        ;
                                      </>
                                    );
                                  })
                                ) : (
                                  <></>
                                )}
                              </select>
                            </td>
                          </tr>
                        </tbody>
                      </table>
                    </div>
                    <table className="table">
                      <thead>
                        <tr>
                          <th>Regional Training Centers</th>
                        </tr>
                      </thead>
                      <tbody>
                        <tr>
                          <td className="">
                            <select
                              id="rtc"
                              className="form-control mx-2 "
                              onChange={(e) => {
                                handle(e);
                              }}
                            >
                              <option>Select</option>
                              {x.regionalTrainingCentres.map((value, index) => {
                                return (
                                  <option value={value.uuid} key={index}>
                                    {value.name}
                                  </option>
                                );
                              })}
                            </select>
                          </td>
                        </tr>
                      </tbody>
                    </table>

                    <div className="mx-3">
                      <p style={{ float: "left" }} className=" mt-3">
                        {" "}
                        <Link
                          to={`/program-courses/${x.uuid}`}
                          className="text-success"
                        >
                          View Courses
                        </Link>
                      </p>
                      <p style={{ float: "right" }}>
                        {" "}
                        {/* <button
                            className=" btn btn-success"
                            onClick={() => {
                               applyForProgrmme(x.uuid);
                            }}
                          >
                            Apply
                          </button> */}
                      </p>
                    </div>
                  </div>
                </div>
              </div>
            ))
          ) : (
            <>
              <div class="card mx-5">
                <div class="card-body ">
                  <h1>No Programs available</h1>
                </div>
              </div>
            </>
          )}
        </div>
      </section>
    </>
  );
};

export default Programs;
