import React, { Component, useEffect, useState } from "react";
import { Link, useParams } from "react-router-dom";
import Alert from "../../constants/Alert";
import Spinner from "../../constants/Spinner";
import { useLocation } from "react-router-dom";
import axios from "axios";
import { useNavigate } from "react-router-dom";
import Urls from "../../constants/Urls";
import { ToastContainer, toast } from "react-toastify";

const ResetPassword = () => {
  document.title = "Reset Password";
  const [email, setEmail] = useState(null);
  const [password, setPassword] = useState(null);
  const [password2, setPassword2] = useState(null);
  const location = useLocation();
  const navigate = useNavigate();
  const params = new URLSearchParams(location.search);

  const resetPasswordBtn = async (e) => {
    e.preventDefault();
    const details = {
      token: params.get("token"),
      email: email,
      password: password,
      password_confirmation: password2,
    };
    await axios
      .post(Urls.api + "auth/reset-password", details)
      .then((res) => {
        if (res) {
          toast.success("Password Reset Successfully,kindly proceed to login");
        }
      })
      .catch((err) => {
        toast.warning(err.message);
      });
  };
  useEffect(() => {});
  return (
    <div className="row">
      <ToastContainer pauseOnFocusLoss={false} />
      <div className="bg-success col-md-12">
        <div class="py-32pt">
          <div class="container d-flex flex-column flex-md-row align-items-center mt-5">
            <i className="fa fa-graduation-cap fa-5x text-white"></i>
            <div class="flex mb-32pt mb-md-0">
              <h4 class="text-white mb-0"> Password Reset</h4>
              <p class="lead measure-lead text-white-50">Account Management</p>
            </div>
          </div>
        </div>
      </div>

      <div className="col-md-12">
        <div class="row col-md-5 p-0 mx-auto">
          <div class="container mt-5">
            <div class="form-group">
              <label for="email">Email:</label>
              <form
                onSubmit={(e) => {
                  resetPasswordBtn(e);
                }}
              >
                <input
                  value={email}
                  placeholder="Email"
                  onChange={(e) => setEmail(e.target.value)}
                  type="email"
                  required
                  class="form-control"
                />
                <br></br>
                <input
                  value={password}
                  required
                  placeholder="Password"
                  onChange={(e) => setPassword(e.target.value)}
                  type="password"
                  class="form-control"
                />
                <br></br>
                <input
                  value={password2}
                  required
                  placeholder="Confirm Password"
                  onChange={(e) => setPassword2(e.target.value)}
                  type="password"
                  class="form-control"
                />{" "}
                <br></br>
                <input
                  type="submit"
                  value="Reset Password"
                  class="btn btn-lg btn-block btn-success"
                />
              </form>
            </div>
            <div class="text-center">
              <p class="text-right mt-2">
                <Link to="/login" class="large">
                  Remember password? Login!
                </Link>
              </p>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ResetPassword;
