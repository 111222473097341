import React, { Component, useState, useEffect } from "react";
import { Link } from "react-router-dom";
import Urls from "../constants/Urls";
import axios from "axios";
import "../App.css";
import {
  Button,
  Container,
  Nav,
  Alert,
  Navbar,
  NavDropdown,
  Offcanvas,
} from "react-bootstrap";

const Header = () => {
  const [token, setToken] = useState(localStorage.getItem("token"));
  const [accountType, setAccountType] = useState(
    localStorage.getItem("accountType")
  );

  const redirectToDashboard = () => {
    if (accountType === "trainee") {
      window.location.replace(Urls.traineeUrl + "dashboard?punch=" + token);
    } else if (accountType === "trainer") {
      window.location.replace(Urls.trainerUrl + "dashboard?punch=" + token);
    } else if (accountType === "company") {
      window.location.replace(Urls.sponsorUrl + "dashboard?punch=" + token);
    } else {
      window.location.replace(Urls.home + "login");
    }
  };

  const logOut = () => {
    const postOptions = {
      method: "POST",
      headers: {
        Authorization: `Bearer ${token}`,
        Accept: "application/json",
        "Content-Type": "application/json",
        "Access-Control-Allow-Credentials": true,
      },
    };

    fetch(Urls.api + "auth/logout", postOptions)
      .then((response) => response.json())
      .then((json) => {
        if (json.success === false) {
          //alert(json.message)
          localStorage.removeItem("token");
          window.location.href = "/";
        } else if (json.success === true) {
          localStorage.removeItem("token");
          window.location.href = "/";
        } else if (json.message !== null) {
          localStorage.removeItem("token");
          window.location.href = "/";
        } else {
          console.log("Login failed");
        }
      })
      .catch((error) => {
        console.log(error);
      })
      .finally(() => console.log("Log out"));
  };

  const [categories, setCategories] = useState([]);

  useEffect(() => {
    (async () => {
      const categoriess = Urls.api + "course-categories/";
      await axios.get(categoriess).then((result) => {
        const categories = result.data.data;
        setCategories(categories);
        console.log(categories);
      });
    })();
  }, []);

  return (
    <header class=" main-header header-style-three">
      <div class=" desktop-nav header-lower">
        <div class="lower-inner">
          <div class="outer-container">
            <div class="inner-container clearfix">
              <div class="pull-left logo-box">
                <div class="logo">
                  <a href="/">
                    <img src="/assets/images/logo-2.png" alt="" title="" />
                  </a>
                </div>
              </div>

              <ul class="language-nav">
                <li>
                  <a href="#">Eng</a>
                </li>
                <li>
                  <a href="#">Dut</a>
                </li>
              </ul>

              <div class="pull-right nav-outer clearfix">
                <div class="mobile-nav-toggler">
                  <span class="icon flaticon-menu-2"></span>
                </div>

                <nav class="main-menu show navbar-expand-md">
                  <div class="navbar-header">
                    <button
                      class="navbar-toggler"
                      type="button"
                      data-toggle="collapse"
                      data-target="#navbarSupportedContent"
                      aria-controls="navbarSupportedContent"
                      aria-expanded="false"
                      aria-label="Toggle navigation"
                    >
                      <span class="icon-bar"></span>
                      <span class="icon-bar"></span>
                      <span class="icon-bar"></span>
                    </button>
                  </div>

                  <div
                    class="navbar-collapse collapse clearfix"
                    id="navbarSupportedContent"
                  >
                    <ul class="navigation clearfix">
                      <li class="current dropdown">
                        <Link to="/">Home</Link>
                      </li>
                      <li class="dropdown">
                        <a href="#">
                          Courses <i className="fa fa-chevron-down"></i>{" "}
                        </a>
                        <ul>
                          {categories.slice(0, 1).map((x, key) => (
                            <React.Fragment key={key}>
                              <li>
                                <Link to="/technical_courses" id={x.uuid}>
                                  {categories[0].name}
                                </Link>
                              </li>
                              <li>
                                <Link to="/non_technical_courses" id={x.uuid}>
                                  {categories[1].name}
                                </Link>
                              </li>
                            </React.Fragment>
                          ))}
                        </ul>
                      </li>
                      <li class="dropdown">
                        <a href="#">
                          Programmes <i className="fa fa-chevron-down"></i>
                        </a>
                        <ul>
                          <li>
                            <Link to="/ngsdp">NGSDP</Link>
                          </li>
                          <li>
                            <Link to="ntsap">NTSAP</Link>
                          </li>
                          <li>
                            <Link to="/programs">Apply For Programs</Link>
                          </li>
                        </ul>
                      </li>
                      <li class="dropdown">
                        <a href="#">
                          Support <i className="fa fa-chevron-down"></i>
                        </a>
                        <ul>
                          <li>
                            <Link to="/help_support">Help and Support</Link>
                          </li>
                          <li>
                            <Link to="/faq">FAQ</Link>
                          </li>
                        </ul>
                      </li>
                    </ul>
                  </div>
                </nav>

                <div class="d-flex row outer-box justify-content-center">
                  <div class="col-2">
                    <span class="icon fa fa-search"></span>{" "}
                  </div>
                  <div
                    style={{ display: token == null ? `block` : `none` }}
                    class="col-4"
                  >
                    <Link
                      to="/login"
                      class="btn btn-sm bg-secondary text-white"
                    >
                      Login
                    </Link>
                  </div>

                  <div
                    style={{ display: token === null ? `block` : `none` }}
                    class="col-4"
                  >
                    <Link to="/signup" class="btn btn-sm btn-success">
                      Sign Up <i className="fa fa-lock"></i>
                    </Link>
                  </div>

                  <div
                    style={{ display: token !== null ? `block` : `none` }}
                    class="col-4"
                  >
                    <Link
                      onClick={() => {
                        redirectToDashboard();
                      }}
                      to="#"
                      class="btn btn-sm btn-success"
                    >
                      Dashboard
                    </Link>
                  </div>
                  <div
                    style={{ display: token !== null ? `block` : `none` }}
                    class="col-4 ml-1"
                  >
                    <Link
                      onClick={() => {
                        logOut();
                      }}
                      to="#"
                      class="btn btn-sm bg-secondary text-white"
                    >
                      Logout <i className="fa fa-sign-out"></i>
                    </Link>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <Navbar
        collapseOnSelect
        expand="sm"
        bg="light"
        variant="light"
        fixed="top"
        className="p-3"
      >
        <Container>
          <Navbar.Brand href="#home">
            <div class="pull-left logo-box">
              <div class="logo">
                <a href="/">
                  <img src="/assets/images/logo-2.png" alt="" title="" />
                </a>
              </div>
            </div>
          </Navbar.Brand>
          <Navbar.Toggle
            aria-controls="responsive-navbar-nav"
            placement="start"
          />
          <Navbar.Collapse id="responsive-navbar-nav">
            <Nav className="me-auto">
              <Nav.Link href="/">Home</Nav.Link>
              <NavDropdown title="Courses" id="collasible-nav-dropdown">
                {categories.slice(0, 1).map((x, key) => (
                  <>
                    <NavDropdown.Item href="/technical_courses" id={x.uuid}>
                      {categories[0].name}
                    </NavDropdown.Item>
                    <NavDropdown.Item href="/non_technical_courses" id={x.uuid}>
                      {categories[1].name}
                    </NavDropdown.Item>
                  </>
                ))}
              </NavDropdown>
              <NavDropdown title="Programmes" id="collasible-nav-dropdown">
                <NavDropdown.Item href="/ngsdp">NGSDP</NavDropdown.Item>
                <NavDropdown.Item href="/ntsap">NTSAP</NavDropdown.Item>
              </NavDropdown>
              <NavDropdown title="Supports" id="collasible-nav-dropdown">
                <NavDropdown.Item href="/help_support">
                  Help and Support
                </NavDropdown.Item>
                <NavDropdown.Item href="/faq">FAQs</NavDropdown.Item>
              </NavDropdown>
            </Nav>
            <Nav className="right-extend">
              <Nav.Link
                href="/login"
                className="btn btn-success log"
                style={{ display: token == null ? `block` : `none` }}
              >
                Login
              </Nav.Link>
              <Nav.Link
                href="/signup"
                className="btn btn-success log"
                style={{ display: token == null ? `block` : `none` }}
              >
                SignUp
              </Nav.Link>
              <Nav.Link
                className="btn btn-success log "
                style={{ display: token != null ? `block` : `none` }}
                onClick={() => {
                  redirectToDashboard();
                }}
              >
                Dashboard
              </Nav.Link>
              <Nav.Link
                className="btn btn-secondary log text-white"
                style={{ display: token != null ? `block` : `none` }}
                onClick={() => {
                  logOut();
                }}
              >
                LogOut <i className="fa fa-sign-out"></i>
              </Nav.Link>
            </Nav>
          </Navbar.Collapse>
        </Container>
      </Navbar>
    </header>
  );
};

export default Header;
