import React, { Component, useState } from "react";
import { Link } from "react-router-dom";
import Icons from "../constants/Icons";
import "../constants/custom.css";

const QuickLinks = () => {
  const [title, setTitle] = useState("Quick Links");

  let quickLinksOptions = [
    {
      id: 1,
      title: "Trainee Signup",
      logo: Icons.graduate,
      bgImage: Icons.graduate_bg,
      url: "/signup",
    },
    {
      id: 2,
      title: "Trainer Signup",
      logo: Icons.trainer,
      bgImage: Icons.trainer_bg,
      url: "/signup",
    },
    {
      id: 3,
      title: "Upcoming Trainings",
      logo: Icons.badge,
      bgImage: Icons.badge_bg,
      url: "/upcoming_trainings",
    },
    {
      id: 4,
      title: "Training Centres",
      logo: Icons.building,
      bgImage: Icons.building_bg,
      url: "/training-centres",
    },
    {
      id: 5,
      title: "Programs & Courses",
      logo: Icons.books,
      bgImage: Icons.books_bg,
      url: "/programmes_and_courses",
    },
    {
      id: 6,
      title: "Training Calendar",
      logo: Icons.calendar,
      bgImage: Icons.calendar_bg,
      url: "/calendar",
    },
  ];

  return (
    <div className="row bg-grey top-mobile lg-screen">
      <div class="auto-container">
        <div class=" centered">
          <h5 className="text-black mb-3">{title}</h5>
        </div>
        <div className="row shift-right">
          {quickLinksOptions.map((x) => (
            <div className="col-sm-12 col-md-4 tiles">
              <a href={x.url} class="">
                <img
                  className=""
                  style={{ width: "100%", height: "180px" }}
                  src={x.bgImage}
                />
                <img className="QuickLinkLogo" src={x.logo} />
                <span className="QuickLinkText">{x.title}</span>
              </a>
            </div>
          ))}
        </div>
      </div>
    </div>
  );
};

export default QuickLinks;
