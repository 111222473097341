import React, { Component } from "react";
import Features from "./Features";
import HomeNews from "./HomeNews";
import QuickLinks from "./QuickLinks";
import Slider from "./Slider";
import TrainedCompanies from "./TrainCompanies";

const Home = () => {
  document.title = "NAPTIN";
  return (
    <>
      <div className="row mb-5">
        <div className="col-sm-12 col-md-7 col-lg-8">
          <Slider />
        </div>
        <div className="col-sm-12 col-md-5 col-lg-4">
          <QuickLinks />
        </div>
      </div>

      <Features />
      <TrainedCompanies />
      <HomeNews />
    </>
  );
};

export default Home;
