import React, { Component, useState, useEffect } from "react";
import { Link, useLocation, useParams } from "react-router-dom";
import Alert from "../../constants/Alert";
import ErrorMessages from "../../constants/ErrorMessages";
import Spinner from "../../constants/Spinner";
import { useNavigate } from "react-router-dom";
import Urls from "../../constants/Urls";

import axios from "axios";

const VerifyEmail = () => {
  const navigate = useNavigate();
  document.title = "Verify Email";

  const search = useLocation().search;
  //    const id = new URLSearchParams(search).get('id');
  //    const hash = new URLSearchParams(search).get('hash');
  const { id } = useParams();
  const { hash } = useParams();

  const [loading, setLoading] = useState(false);
  const [showAlert, setShowAlert] = useState(false);
  const [alertType, setAlertType] = useState(null);
  const [alertMessage, setAlertMessage] = useState(null);
  const [alertTitle, setAlertTitle] = useState(null);

  const token = localStorage.getItem("token");

  const [showLoginBtn, setShowLoginBtn] = useState(false);

  const _expires = useLocation().search;
  const expires = new URLSearchParams(_expires).get("expires");

  const _signature = useLocation().search;
  const signature = new URLSearchParams(_signature).get("signature");

  const verifyEmail = () => {
    setShowAlert(false);
    setLoading(true);

    const postOptions = {
      method: "GET",
      headers: {
        Authorization: `Bearer ${token}`,
        "Content-Type": "application/json",
        Accept: "application/json",
        "Access-Control-Allow-Credentials": true,
      },
    };

    fetch(
      Urls.api +
        "auth/verify/" +
        id +
        "/" +
        hash +
        "?expires=" +
        expires +
        "&signature=" +
        signature,
      postOptions
    )
      .then((response) => response.json())
      .then((json) => {
        if (json.success === false) {
          setAlertMessage(
            json.message + " Kindly login and click the verification link again"
          );
          setAlertTitle("Error!");
          setAlertType("danger");
          setShowAlert(true);
          setShowLoginBtn(true);
        } else if (json.success === true) {
          setAlertMessage(json.message);
          setAlertTitle("Success!");
          setAlertType("success");
          setShowAlert(true);
          setShowLoginBtn(true);
        } else {
          setAlertMessage(
            json.message + " Kindly login and click the verification link again"
          );
          setAlertTitle("Error!");
          setAlertType("danger");
          setShowAlert(true);
          setShowLoginBtn(true);
        }
      })
      .catch((error) => {
        setAlertMessage(ErrorMessages.UnkownRetry);
        setAlertTitle("Error!");
        setAlertType("danger");
        setShowAlert(true);
        setShowLoginBtn(true);
      })
      .finally(() => setLoading(false));
    // })
  };

  useEffect(() => {
    verifyEmail();
  }, []);

  return (
    <div className="row">
      <div className="bg-success col-md-12">
        <div class="py-32pt">
          <div class="container d-flex flex-column flex-md-row align-items-center mt-5">
            {/* <i className='fa fa-graduation-cap fa-3x text-white'></i> */}
            <div class="flex mb-32pt mb-md-0">
              <h4 class="text-white mb-0"> Account</h4>
              <p class="lead measure-lead text-white-50">Email Verification</p>
            </div>
          </div>
        </div>
      </div>

      <div className="col-md-12">
        <div class="row col-md-12 p-0 mx-auto">
          <div class="container mt-5 mb-10">
            {showAlert ? (
              <Alert
                type={alertType}
                title={alertTitle}
                message={alertMessage}
              />
            ) : (
              ""
            )}

            <div
              className="row mb-5"
              style={{ display: showLoginBtn ? `block` : `none` }}
            >
              <div className="col-md-12 text-center">
                To proceed, kindly click the button below
              </div>
              <div className="col-md-12">
                <Link
                  to={"/login"}
                  className="btn btn-block btn-sm btn-success"
                >
                  {" "}
                  Proceed to Login{" "}
                </Link>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default VerifyEmail;
