import React, { Component, useEffect, useState } from "react";
import { Link, useParams } from "react-router-dom";
import TopHeader from "../TopHeader";
import "../../constants/custom.css";
import Spinner from "../../constants/Spinner";
import Alert from "../../constants/Alert";
import SupportRightSideBar from "./SupportRightSideBar";
import urls from "../../constants/Urls";
import axios from "axios";
import Images from "../../constants/Images";

const Faq = () => {
  const { id } = useParams();
  const [title, setTitle] = useState("Frequently Asked Questions");
  const [loading, setLoading] = useState(false);
  const [questions, setQuestions] = useState([]);

  const [activeRow, setActiveRow] = useState(1);
  const [expandAll, setExpandAll] = useState(false);
  const [collapseAll, setCollapseAll] = useState(false);

  const [search, setSearch] = useState("");
  const [getfaqs, setGetFaqs] = useState([]);

  useEffect(() => {
    (async () => {
      await axios.get(urls.api + "faqs/published").then((result) => {
        const gottenFaqs = result.data.data;
        setGetFaqs(gottenFaqs);
      });
    })();
  }, []);

  const searchFaq = (y) => {
    setLoading(true);
    // const event = getfaqs.filter(x => x.faq_content.includes(y))
    const event = getfaqs.filter((x) => {
      if (search === "") {
        return x;
      } else {
        return x.faq_content.includes(y).toString().toLowerCase();
      }
    });
    setQuestions(event);
    setLoading(false);
  };

  const openQuestion = (x) => {
    setCollapseAll(false);
    setExpandAll(false);
    setActiveRow(x);
  };

  const expandAllQuestions = () => {
    setCollapseAll(false);
    setExpandAll(true);
  };

  const collapseAllQuestions = () => {
    setCollapseAll(true);
    setExpandAll(false);
  };

  return (
    <div class="auto-container">
      <div className="row mt-5">
        <TopHeader title={title} />

        <div class="container mt-5">
          <div className="row">
            <div class="content-column col-lg-8 cl-md-12 col-sm-12">
              <div className="row">
                <div class="col-md-6">
                  <div className="input-group">
                    <input
                      className="form-control"
                      placeholder="Search"
                      value={search}
                      name="search"
                      onChange={(x) => setSearch(x.target.value)}
                      type={"search"}
                    />
                    <div className="input-group-append">
                      {!loading ? (
                        <span
                          onClick={() => searchFaq(search)}
                          className="btn btn-success"
                        >
                          {" "}
                          <i className="fa fa-search"></i> Search
                        </span>
                      ) : (
                        <Spinner />
                      )}
                    </div>
                  </div>
                </div>

                <div className="col-md-12 text-dark">
                  <div className="row">
                    <div
                      onClick={() => expandAllQuestions()}
                      className="col-md-4 mt-5 mb-3"
                    >
                      <button className="btn btn-sm btn-secondary">
                        <i className="fa fa-plus-circle"></i> Expand All
                      </button>
                    </div>
                    <div
                      onClick={() => collapseAllQuestions()}
                      className="col-md-4 mt-5 mb-3"
                    >
                      <button className="btn btn-sm btn-secondary">
                        <i className="fa fa-minus-circle"></i> Collase All
                      </button>
                    </div>
                  </div>

                  <ul class="accordion-box">
                    {getfaqs.map((x, key) => (
                      <li class="accordion block" key={key}>
                        <div onClick={() => openQuestion(x.id)} class="acc-btn">
                          {x.faq_name}{" "}
                          <div
                            class={
                              expandAll
                                ? "icon fa fa-angle-up"
                                : collapseAll
                                ? "icon fa fa-angle-down"
                                : activeRow == x.id
                                ? "icon fa fa-angle-up"
                                : "icon fa fa-angle-down"
                            }
                          ></div>
                        </div>
                        <div
                          class="acc-content current"
                          style={{
                            display: expandAll
                              ? "block"
                              : collapseAll
                              ? "none"
                              : activeRow == x.id
                              ? "block"
                              : "none",
                          }}
                        >
                          <div class="content">
                            <div class="text-dark text-left">
                              {x.faq_content}
                            </div>
                          </div>
                        </div>
                      </li>
                    ))}
                  </ul>
                </div>
              </div>
            </div>

            <SupportRightSideBar />
          </div>
        </div>
      </div>
    </div>
  );
};

export default Faq;
