import { useEffect, useState } from "react";
import { Link, useParams } from "react-router-dom";
import Spinner from "../../constants/Spinner";
import urls from "../../constants/Urls";
import { format } from "date-fns";

const TrainerViewCourse = () => {
  document.title = "Course Details";

  const [loading, setLoading] = useState(false);
  const [getDetails, setGetDetails] = useState([]);
  const [getCalendar, setGetCalendar] = useState([]);
  const [getRtc, setGetRtc] = useState([]);
  const [startDate, setStartDate] = useState("");
  const [endDate, setEndDate] = useState("");
  const [duration, setDuration] = useState("");
  const { id } = useParams();
  const [closeDate, setCloseDate] = useState("");
  function findObjectByDate(array, date) {
    for (let i = 0; i < array.length; i++) {
      if (new Date(array[i].startDate).getTime() === date.getTime()) {
        return array[i];
      }
    }
    return null;
  }
  const calculateDuration = (e) => {
    const dateStr = e.target.value;
    const dates = dateStr.split("-");
    const date1 = new Date(dates[0]);
    const date2 = new Date(dates[1]);
    setStartDate(new Date(date1).toLocaleDateString("en-US"));
    setEndDate(new Date(date2).toLocaleDateString("en-US"));
    const Difference_In_Time =
      new Date(date2).getTime() - new Date(date1).getTime();
    const Difference_In_Days = Difference_In_Time / (1000 * 3600 * 24);
    setDuration(Difference_In_Days + 1 + " Days");
    const searchDate = new Date(date1);
    const result = findObjectByDate(getCalendar, searchDate);
    setCloseDate(new Date(result.payCloseDate).toLocaleDateString("en-US"));
  };
  const getCourseDetails = () => {
    setLoading(true);
    fetch(urls.api + "courses/" + id)
      .then((response) => response.json())
      .then((json) => {
        const getDetails = json.data;
        setGetDetails(getDetails);
        const getCalendar = json.data.calendars;
        setGetCalendar(getCalendar);
        const getRtc = getDetails.regionalTrainingCenters;
        setGetRtc(getRtc);
      })
      .catch((error) => {})
      .finally(() => setLoading(false));
  };

  const DATE_OPTIONS = {
    weekday: "short",
    year: "numeric",
    month: "short",
    day: "numeric",
  };
  useEffect(() => {
    getCourseDetails();
  }, []);

  return (
    <div class="content container-fluid pb-5 px-lg-5">
      <div style={{ display: loading ? `block` : `none` }}>
        <Spinner />
      </div>

      <div style={{ display: !loading ? `block` : `none` }}>
        <div class="row">
          <div class="col-lg-8 col-12 mx-auto" style={{ marginTop: "10rem" }}>
            <Link to={"#"}>
              <div class="card">
                <div class="card-body">
                  <div class="dash-widget-header">
                    <div className="row">
                      <div class="col-12 text-center mb-3">
                        <div
                          class="w-100"
                          style={{ width: " 250px", height: "300px" }}
                        >
                          <img
                            className="avatar-img rounded w-100  "
                            src={getDetails.image}
                            style={{
                              height: "100%",
                              width: "100%",
                              objectFit: "contain",
                            }}
                          />
                        </div>
                      </div>
                      <div class="col-12">
                        <div class="dash-widget-info">
                          <h6 class="text-success text-center py-5">
                            {getDetails.title}
                          </h6>
                        </div>
                      </div>
                    </div>
                  </div>
                  <table className="table text-dark">
                    <tbody>
                      <tr>
                        <td>Starts</td>
                        <th className="text-right">
                          <select
                            id="date"
                            class="form-control"
                            style={{ width: "500px", float: "right" }}
                            onChange={(e) => {
                              calculateDuration(e);
                            }}
                          >
                            <option>Select</option>
                            {getCalendar.length ? (
                              getCalendar.map((each) => {
                                return (
                                  <>
                                    {" "}
                                    <option
                                      value={
                                        new Date(
                                          each.startDate
                                        ).toLocaleDateString(
                                          "en-US",
                                          DATE_OPTIONS
                                        ) +
                                        " - " +
                                        new Date(
                                          each.endDate
                                        ).toLocaleDateString(
                                          "en-US",
                                          DATE_OPTIONS
                                        )
                                      }
                                    >
                                      {new Date(
                                        each.startDate
                                      ).toLocaleDateString(
                                        "en-US",
                                        DATE_OPTIONS
                                      ) +
                                        " - " +
                                        new Date(
                                          each.endDate
                                        ).toLocaleDateString(
                                          "en-US",
                                          DATE_OPTIONS
                                        )}
                                    </option>
                                    ;
                                  </>
                                );
                              })
                            ) : (
                              <></>
                            )}
                          </select>
                        </th>
                      </tr>
                      <tr>
                        <td>Duration</td>
                        <th className="text-right">
                          {/* {getCalendar.map((value, index) => {
                        return (
                            <span key={index}>
                            {value.duration} weeks {"|"}{" "}
                            </span>
                        );
                    })} */}
                          <p>{duration}</p>
                        </th>
                      </tr>
                      <tr>
                        <td>Payment Close Date</td>
                        <th className="text-right">
                          <span>
                            {closeDate
                              ? new Date(closeDate).toLocaleDateString(
                                  "en-US",
                                  DATE_OPTIONS
                                )
                              : ""}
                          </span>
                        </th>
                      </tr>
                      <tr>
                        <td>Course Type</td>
                        <th className="text-right">{getDetails.courseType}</th>
                      </tr>
                      <tr>
                        <td>Description</td>
                        <th className="text-right">{getDetails.description}</th>
                      </tr>
                      <tr>
                        <td>Trainer</td>
                        <th className="text-right">
                          {getDetails.trainer != null ? (
                            getDetails.trainer.firstName +
                            " " +
                            getDetails.trainer.surname
                          ) : (
                            <p>None</p>
                          )}
                        </th>
                      </tr>
                      <tr>
                        <td>Cost</td>
                        <th className="text-right">{getDetails.cost}</th>
                      </tr>
                      <tr>
                        <td>Min. Deposit</td>
                        <th className="text-right">{getDetails.minDeposit}</th>
                      </tr>
                      <tr>
                        <td>Regional Training Centres</td>
                        <th className="text-right">
                          {getRtc.length != 0 ? (
                            <>
                              {getRtc.map((value, index) => {
                                return (
                                  <a href="https://naptinportal.com/training-centres">
                                    <span key={index}>
                                      {value.name} {"|"}{" "}
                                    </span>
                                  </a>
                                );
                              })}
                            </>
                          ) : (
                            <p>None</p>
                          )}
                        </th>
                      </tr>
                    </tbody>
                  </table>
                  <Link
                    to={"/login"}
                    // className="btn btn-primary btn-sm btn-block"
                  >
                    <button className="btn btn-secondary  btn-block">
                      {" "}
                      Apply Now
                    </button>
                  </Link>{" "}
                </div>
              </div>
            </Link>
          </div>
        </div>
      </div>
    </div>
  );
};

export default TrainerViewCourse;
