import React, { Component } from "react";
import Images from "../../constants/Images";

const Ngsdp = () => {
  document.title = "NGSDP";
  return (
    <>
      <section
        class="banner-section-four"
        style={{ backgroundColor: "#EFF8FA" }}
      >
        <div class="auto-container">
          <div class="row clearfix">
            <div class="content-column col-lg-6 cl-md-12 col-sm-12">
              <div class="inner-column">
                <h2 className="text-dark">
                  NGSDP: NAPTIN Graduate
                  <br />
                  Skills Development <br />
                  Program
                </h2>
                <h5 className="text-dark mt-2">
                  Building Indigenous skills and competencies for the <br />
                  power sector
                </h5>
              </div>
            </div>

            <div class="image-column col-lg-6 cl-md-12 col-sm-12">
              <div class="inner-column">
                <div class="color-layer"></div>
                <div
                  class="icon-layer-four"
                  style={{
                    backgroundImage: `url(/assets/images2/background/pattern-7.png)`,
                  }}
                ></div>
                <div
                  class="icon-layer-five"
                  style={{
                    backgroundImage: `url(/assets/images2/icons/icon-12.png)`,
                  }}
                ></div>
                <div
                  class="icon-layer-six"
                  style={{
                    backgroundImage: `url(/assets/images2/icons/icon-3.png)`,
                  }}
                ></div>
                <div
                  class="icon-layer-seven"
                  style={{
                    backgroundImage: `url(/assets/images2/icons/icon-12.png)`,
                  }}
                ></div>
                <div class="image">
                  <img
                    src={Images.ntsap}
                    style={{ width: `100%`, height: `350px` }}
                    alt=""
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>

      <div class="row">
        <div class="col-lg-11 col-md-12 col-sm-12">
          <div class="mt-5 m-4">
            NAPTIN is calling for University & Polytechnic graduates who desire
            a career in the Nigerian power industry after privatisation to apply
            for a one year graduate skills development programme aimed at
            improving their potentials for rewarding careers in the power
            sector.
          </div>
          <div class="mt-5 m-4">
            <center>
              <img
                className="text-center"
                src={Images.img_three}
                style={{ width: "700px", height: "500px" }}
              />
            </center>
          </div>
          <div class="mt-5 m-4">
            <h6>
              <b>Introduction</b>
            </h6>
            <br />
            The National Power Training Institute of Nigeria (NAPTIN) is an
            agency of the Federal Government of Nigeria, operating under the
            aegis of the Federal Ministry of Power. It was established on 23rd
            March 2009 and commenced full operation in September, 2009. The
            primary purpose for its establishment is to provide training for
            power sector personnel and coordinate training activities in the
            sector. In pursuit of this mandate, NAPTIN took over the management
            of the PHCN regional training centres. Through the Headquarters in
            Abuja and the eight Regional Training Centres around the country,
            NAPTIN delivers training on various power sector courses to a wide
            range of clients.
            <br />
            <br />
            NAPTIN has identified the skills that will be needed by the various
            power companies in the power industry after privatisation.
            Consequently, there are needs for skilled workers at all levels and
            in all categories, including Generation, Transmission, Distribution,
            Instrumentation and Controls, Safety, Project Management,
            Communication Technologies, etc.
          </div>
          <div class="mt-5 m-4">
            <h6>
              <b>Requirements and Application</b>
            </h6>
            <br />
            Applicants should be University/Polytechnic Engineering graduates
            who have completed their National Youth Service Corps programme, and
            will be required to show evidence of these during the admission
            process. Applicants will need to fund the cost of the programme and
            also their accommodation and subsistence but they may be able to
            find sponsorship, for example from their State, Local Government,
            companies, or any other sponsoring body.
          </div>
          <div class="mt-5 m-4">
            <h6>
              <b>NGSDP Online Application Tips</b>
            </h6>
            <br />
            <ol>
              <li>
                1. Signup to the NAPTIN Training Management Information System
                if you do not have an account (click here to SignUp).
              </li>
              <li>
                2. If you already have an account, login to the NAPTIN TMIS
                portal
              </li>
              <li>3. Goto to the Training Applications Page</li>
              <li>
                4. Select the NGSDP Training Program and proceed with the
                application
              </li>
            </ol>
          </div>
          <div class="mt-5 m-4">
            <h6>
              <b>Further Enquiries</b>
            </h6>
            <br />
            For further Enquiries, please contact the NAPTIN TMIS Help Desk:
            <br />
            <br />
            <b>
              NAPTIN Corporate Headquarters
              <br />
              Plot 21, Cadastral Zone,
              <br />
              Idu Industrial Area, Idu District,
              <br />
              Off Airport Road,
              <br />
              FCT Abuja, Nigeria
            </b>
            <br />
            <br />
            Email: support@naptinportal.com, info@naptin.org.ng
            <br />
            Telephone: 2347067777818; 23408057777266.
          </div>
        </div>

        {/*<div class="col-lg-4 col-md-12 col-sm-12">
                            <div class="col-12 mt-5 m-4">
                                Right Contents
                                </div>
                            </div>*/}
      </div>
    </>
  );
};

export default Ngsdp;
