import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import AliceCarousel from "react-alice-carousel";
import "react-alice-carousel/lib/alice-carousel.css";
import Images from "../../constants/Images";
import axios from "axios";
import "./Course.css";
import urls from "../../constants/Urls";
const NonTechnicalCourses = () => {
  document.title = "Non-Technical Courses";
  const [courses, setCourses] = useState([]);

  useEffect(() => {
    (async () => {
      const allCategories = urls.api + "course-categories";
      axios.get(allCategories).then((result) => {
        const uuid = result.data.data[1].uuid;

        const specificCategory = urls.api + "courses/category/" + uuid;
        axios.get(specificCategory).then((result) => {
          const courses = result.data.data.courses;
          setCourses(courses);
        });
      });
    })();
  }, []);

  return (
    <>
      <section class=" pt-5" style={{ backgroundColor: "#EFF8FA" }}>
        <div class="auto-container sliderimage-non mb-5">
          <div class="row clearfix">
            <div class="content-column col-lg-6 cl-md-12 col-sm-12">
              <div class="inner-column">
                <h1 className="text-dark">
                  NAPTINS
                  <br />
                  Non-Technical Courses
                </h1>
                <h5 className="text-dark mt-2"> ... </h5>
              </div>
            </div>

            <div class="image-column col-lg-6 cl-md-12 col-sm-12">
              <div class="inner-column">
                <div class="color-layer"></div>
                <div
                  class="icon-layer-four"
                  style={{
                    backgroundImage: `url(/assets/images2/background/pattern-7.png)`,
                  }}
                ></div>
                <div
                  class="icon-layer-five"
                  style={{
                    backgroundImage: `url(/assets/images2/icons/icon-12.png)`,
                  }}
                ></div>
                <div
                  class="icon-layer-six"
                  style={{
                    backgroundImage: `url(/assets/images2/icons/icon-3.png)`,
                  }}
                ></div>
                <div
                  class="icon-layer-seven"
                  style={{
                    backgroundImage: `url(/assets/images2/icons/icon-12.png)`,
                  }}
                ></div>
                <div class="image">
                  <img
                    src={Images.ngsdp_under_program}
                    style={{ width: `100%`, height: `350px` }}
                    alt=""
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>

      <div class="row adjust-top">
        <div class="col-lg-12 col-md-12 col-sm-12">
          <div class="mt-5 m-4">
            <div className="row">
              <div className="col-lg-6 col-md-12 col-sm-12 text-left mb-4">
                <h6>
                  <b>Courses</b>
                </h6>
              </div>
            </div>

            <div className="row">
              {courses.length != 0 ? (
                <>
                  {courses.map((x) => (
                    <div className="col-sm-12 col-md-4 col-lg-3 ">
                      <div
                        class="inner-box wow fadeInLeft animated tech_div"
                        data-wow-delay="0ms"
                        data-wow-duration="1500ms"
                      >
                        <div class="image">
                          <img src={x.image} alt="" className="co_img" />
                          {localStorage.getItem("token") !== null ? (
                            <a
                              href={
                                "https://dashboard.naptinportal.com/trainee/" +
                                x.courseUuid +
                                "/view_course"
                              }
                            >
                              <button class="EnrollBtn btn btn-success btn-sm">
                                ENROL NOW
                              </button>
                            </a>
                          ) : (
                            <a href="/login">
                              <button class="EnrollBtn btn btn-success btn-sm">
                                ENROL NOW
                              </button>
                            </a>
                          )}
                        </div>
                        <div class="lower-content">
                          <h4 className="mx-2 ">
                            <a
                              href={"/course_details/" + x.courseUuid}
                              class=""
                            >
                              {x.title}
                            </a>
                          </h4>
                          <div class="mx-2  rating">
                            <span class="fa fa-star"></span>
                            <span class="fa fa-star"></span>
                            <span class="fa fa-star"></span>
                            <span class="fa fa-star"></span>
                            <span class="fa fa-star-o"></span>
                          </div>
                          <div class="mx-2 price">
                            <b>{x.cost.toLocaleString("en")}</b>
                          </div>
                        </div>
                      </div>
                    </div>
                  ))}
                </>
              ) : (
                <h5 className="my-y py-5">
                  “There are no courses presently in this category. Please check
                  back later”
                </h5>
              )}
            </div>
          </div>
        </div>

        {/*<div class="col-lg-4 col-md-12 col-sm-12">
                            <div class="col-12 mt-5 m-4">
                                Right Contents
                                </div>
                            </div>*/}
      </div>
    </>
  );
};

export default NonTechnicalCourses;
