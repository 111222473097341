import React, { Component, useState, useEffect } from "react";
import { Link } from "react-router-dom";
import Icons from "../constants/Icons";
import "../constants/custom.css";
import ekedc from "../constants/Images/ekedcp_logo.jpg";
import guiness from "../constants/Images/Guinness.png";
import ikedc from "../constants/Images/IKEDC.png";
import ikega from "../constants/Images/Ikeja-Electric.png";
import kedc from "../constants/Images/KEDC.jpg";
import mainstream from "../constants/Images/mainstream.png";
import nemsa from "../constants/Images/nemsa.png";
import rural from "../constants/Images/rural-electrification.jpg";
import sahara from "../constants/Images/sahara-group.png";
import tcn from "../constants/Images/tcnLogo.png";
// import axios from 'axios'

const TrainedCompanies = () => {
  const [title, setTitle] = useState("Companies we’ve trained");

  let quickLinksOptions = [
    { id: 1, title: "A", logo: ekedc, url: "" },
    { id: 2, title: "B", logo: guiness, url: "" },
    { id: 3, title: "C", logo: ikedc, url: "" },
    { id: 4, title: "D", logo: tcn, url: "" },
    { id: 5, title: "D", logo: nemsa, url: "" },
    { id: 6, title: "E", logo: mainstream, url: "" },
    { id: 5, title: "D", logo: sahara, url: "" },
    { id: 6, title: "E", logo: rural, url: "" },
  ];

  // useEffect(() => {
  //     (async() => {
  //     const api = "https://api.naptinportal.com/api/v1/get/companies/";
  //     const headers = {
  //         'Authorization': 'Bearer ' + localStorage.getItem('token'),
  //     }

  //     axios.get(api, {headers})
  //         .then(result => {
  //             console.log(result);
  //         })
  //     })()
  // }, [])

  return (
    <div className="row bg-dark">
      <div class="auto-container">
        <div class="sec-title centered">
          <div class="sec-title centered mt-5">
            <h4 className="text-white mt-5">{title}</h4>
          </div>
          <div className="row">
            {quickLinksOptions.map((x) => (
              <div className="col-lg-3 col-md-12 col-sm-12">
                <div className="bg-white mt-4">
                  <img
                    className="text-center"
                    src={x.logo}
                    style={{ maxHeight: "8.5rem" }}
                  />
                  {/* <span className='text-center'>Company {x.title}</span> */}
                </div>
              </div>
            ))}
          </div>
          <div className="row justify-content-center">
            <h6 className="text-white centered mt-5 mb-5">
              1001+ happy Clients worldwide
            </h6>
          </div>
        </div>
      </div>
    </div>
  );
};

export default TrainedCompanies;
