import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import Alert from "../../constants/Alert";
import Spinner from "../../constants/Spinner";
import axios from "axios";
import Urls from "../../constants/Urls";
import { ToastContainer, toast } from "react-toastify";

const ForgetPassword = () => {
  document.title = "Reset Password";
  const [email, setEmail] = useState(null);
  const [code, setCode] = useState(null);
  const [loading, setLoading] = useState(false);
  const [showAlert, setShowAlert] = useState(false);
  const [alertType, setAlertType] = useState(null);
  const [alertMessage, setAlertMessage] = useState(null);
  const [alertTitle, setAlertTitle] = useState(null);

  const ForgetPasswordBtn = async () => {
    await axios
      .post(Urls.api + "auth/forgot-password", { email: email })
      .then((res) => {
        if (res) {
          toast.success("Password reset link has been sent");
        }
      })
      .catch((err) => {
        toast.warning(err.message);
      });
  };
  useEffect(() => {});

  return (
    <div className="row">
      <ToastContainer pauseOnFocusLoss={false} />
      <div className="bg-success col-md-12">
        <div class="py-32pt">
          <div class="container d-flex flex-column flex-md-row align-items-center mt-5">
            <i className="fa fa-graduation-cap fa-5x text-white"></i>
            <div class="flex mb-32pt mb-md-0">
              <h4 class="text-white mb-0"> Password Reset</h4>
              <p class="lead measure-lead text-white-50">Account Management</p>
            </div>
          </div>
        </div>
      </div>

      <div className="col-md-12">
        <div class="row col-md-5 p-0 mx-auto">
          <div class="container mt-5">
            <div class="form-group">
              <label for="email">Email:</label>
              <input
                value={email}
                required
                placeholder="Email"
                onChange={(e) => setEmail(e.target.value)}
                type="email"
                class="form-control"
              />
            </div>
            {showAlert ? (
              <Alert
                type={alertType}
                title={alertTitle}
                message={alertMessage}
              />
            ) : (
              ""
            )}
            <div class="text-center">
              {!loading ? (
                <button
                  onClick={() => ForgetPasswordBtn()}
                  class="btn btn-lg btn-block btn-success"
                >
                  Request Link
                </button>
              ) : (
                <Spinner />
              )}
              <p class="text-right mt-2">
                <Link to="/login" class="large">
                  Remember password? Login!
                </Link>
              </p>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ForgetPassword;
