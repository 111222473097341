import React, { Component, useState } from "react";
import { Link } from "react-router-dom";
import Alert from "../../constants/Alert";
import ErrorMessages from "../../constants/ErrorMessages";
import Spinner from "../../constants/Spinner";
import Urls from "../../constants/Urls";

const SignUp = () => {
  document.title = "Sign Up";
  const [email, setEmail] = useState(null);
  const [password, setPassword] = useState(null);
  const [password2, setPassword2] = useState(null);
  const [phone, setPhone] = useState(null);
  const [firstName, setFirstName] = useState(null);
  const [lastName, setLastName] = useState(null);
  const [gender, setGender] = useState(null);
  const [dateOfBirth, setDateOfBirth] = useState(null);
  const [accountTypeList, setAccountTypeList] = useState([
    { id: 1, type: "trainee", label: "Trainee" },
    { id: 2, type: "trainer", label: "Trainer" },
  ]);
  const [accountType, setAccountType] = useState(null);
  const [loading, setLoading] = useState(false);
  const [showAlert, setShowAlert] = useState(false);
  const [alertType, setAlertType] = useState(null);
  const [alertMessage, setAlertMessage] = useState(null);
  const [alertTitle, setAlertTitle] = useState(null);

  const queryParams = new URLSearchParams(window.location.search);
  const account = queryParams.get("account");

  const loginFunction = (_email, _password) => {
    localStorage.removeItem("token"); //remove token
    setShowAlert(false);
    setLoading(true);

    const postOptions = {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify({
        email: _email,
        password: _password,
      }),
    };

    fetch(Urls.api + "auth/login", postOptions)
      .then((response) => response.json())
      .then((json) => {
        if (json.success !== true) {
          setAlertMessage(json.message);
          setAlertTitle("Error!");
          setAlertType("danger");
          setShowAlert(true);
        } else {
          setShowAlert(false);
          setLoading(false);
          localStorage.setItem("token", json.data.auth_token); //set token on home
          //check if token is set
          if (localStorage.getItem("token") !== null) {
            if (json.data.roles[0] == "trainee")
              window.location.replace(
                Urls.traineeUrl + "register?punch=" + json.data.auth_token
              );
            else if (json.data.roles[0] == "trainer")
              window.location.replace(
                Urls.trainerUrl + "register?punch=" + json.data.auth_token
              );
            else window.location.replace(Urls.traineeUrl + "register");
          } else {
            window.location.replace("/login");
          }
        }
      })
      .catch((error) => {
        setAlertMessage(ErrorMessages.UnkownRetry);
        setAlertTitle("Error!");
        setAlertType("danger");
        setShowAlert(true);
      })
      .finally(() => setLoading(false));
  };

  const signUpBtn = () => {
    setShowAlert(false);
    setLoading(true);

    const postOptions = {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify({
        firstName: firstName,
        surname: lastName,
        dateOfBirth: dateOfBirth,
        gender: gender,
        email: email,
        phoneNumber: phone,
        password: password,
        password_confirmation: password2,
        userType: accountType,
        step: 1,
      }),
    };

    fetch(Urls.api + "auth/register", postOptions)
      .then((response) => response.json())
      .then((json) => {
        if (!json.success) {
          setAlertMessage(json.message);
          setAlertTitle("Error!");
          setAlertType("danger");
          setShowAlert(true);
        } else {
          localStorage.setItem("accountType", json.data.roles[0]);
          setShowAlert(false);
          setLoading(false);
          loginFunction(email, password);
          // window.location.replace("/login")
        }
      })
      .catch((error) => {
        setAlertMessage(ErrorMessages.UnkownRetry);
        setAlertTitle("Error!");
        setAlertType("danger");
        setShowAlert(true);
      })
      .finally(() => setLoading(false));
  };

  return (
    <div className="row">
      <div className="bg-success col-md-12">
        <div class="py-32pt">
          <div class="container d-flex flex-column flex-md-row align-items-center mt-5">
            <i className="fa fa-graduation-cap fa-5x text-white"></i>
            <div class="flex mb-32pt mb-md-0">
              <h4 class="text-white mb-0"> Sign Up</h4>
              <p class="lead measure-lead text-white-50">Account Management</p>
            </div>
            {/* <Link to="/signup" class="text-right text-white flex-column">
                Don't have an account?
                <span class="btn__secondary-text">Sign up Today!</span>
            </Link> */}
          </div>
        </div>
      </div>

      <div className="col-md-12">
        <div class="container mt-5">
          <div class="row p-0 mx-auto">
            <div className="col-md-6">
              <div class="form-group">
                <span style={{ color: "red" }}>*</span>{" "}
                <label for="email">First Name:</label>
                <input
                  required
                  value={firstName}
                  onChange={(e) => setFirstName(e.target.value)}
                  type="email"
                  class="form-control"
                />
              </div>
            </div>

            <div className="col-md-6">
              <div class="form-group">
                <span style={{ color: "red" }}>*</span>{" "}
                <label for="email">Last Name:</label>
                <input
                  required
                  value={lastName}
                  onChange={(e) => setLastName(e.target.value)}
                  type="email"
                  class="form-control"
                />
              </div>
            </div>

            <div className="col-md-6">
              <div class="form-group">
                <span style={{ color: "red" }}>*</span>{" "}
                <label for="email">Gender:</label>
                <select
                  required
                  class="form-control"
                  onChange={(e) => setGender(e.target.value)}
                >
                  <option value={null}>Select</option>
                  <option value={"f"}>Female</option>
                  <option value={"m"}>Male</option>
                </select>
              </div>
            </div>

            <div className="col-md-6">
              <div class="form-group">
                <span style={{ color: "red" }}>*</span>{" "}
                <label for="email">Date of Birth:</label>
                <input
                  required
                  value={dateOfBirth}
                  onChange={(e) => setDateOfBirth(e.target.value)}
                  type="date"
                  class="form-control"
                />
              </div>
            </div>

            <div className="col-md-6">
              <div class="form-group">
                <span style={{ color: "red" }}>*</span>{" "}
                <label for="email">Email:</label>
                <input
                  required
                  value={email}
                  onChange={(e) => setEmail(e.target.value)}
                  type="email"
                  class="form-control"
                />
              </div>
            </div>

            <div className="col-md-6">
              <div class="form-group">
                <span style={{ color: "red" }}>*</span>{" "}
                <label for="email">Phone:</label>
                <input
                  required
                  placeholder="234"
                  value={phone}
                  onChange={(e) => setPhone(e.target.value)}
                  type="tel"
                  class="form-control"
                />
              </div>
            </div>

            <div className="col-md-6">
              <div class="form-group">
                <span style={{ color: "red" }}>*</span>{" "}
                <label for="password">Password:</label>
                <input
                  required
                  value={password}
                  onChange={(e) => setPassword(e.target.value)}
                  type="password"
                  class="form-control"
                />
              </div>
            </div>

            <div className="col-md-6">
              <div class="form-group">
                <span style={{ color: "red" }}>*</span>{" "}
                <label for="password">Confirm Password:</label>
                <input
                  required
                  value={password2}
                  onChange={(e) => setPassword2(e.target.value)}
                  type="password"
                  class="form-control"
                />
              </div>
            </div>

            <div className="col-md-6">
              <div class="form-group">
                <span style={{ color: "red" }}>*</span>{" "}
                <label for="password">Account Type:</label>
                {account != null ? (
                  <input
                    required
                    value={account}
                    onChange={(e) => setAccountType(e.target.value)}
                    type="text"
                    class="form-control"
                    readOnly
                  />
                ) : (
                  <select
                    required
                    class="form-control"
                    onChange={(e) => setAccountType(e.target.value)}
                  >
                    <option value={null}>Select</option>
                    {accountTypeList.map((x) => (
                      <option value={x.type}>{x.label}</option>
                    ))}
                  </select>
                )}
              </div>
            </div>

            {showAlert ? (
              <div className="col-md-12">
                <Alert
                  type={alertType}
                  title={alertTitle}
                  message={alertMessage}
                />
              </div>
            ) : (
              ""
            )}
            <div className="col-md-12">
              <div class="text-center">
                {!loading ? (
                  <button
                    onClick={() => signUpBtn()}
                    class="btn btn-lg btn-block btn-success"
                  >
                    Sign Up
                  </button>
                ) : (
                  <Spinner />
                )}
                <p class="text-right mt-2">
                  <Link to="/login" class="large">
                    Have an account? Login!
                  </Link>
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default SignUp;
