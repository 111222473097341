import { useEffect, useState } from "react";
// import Spinner from '../../constants/Spinner'
import { add } from "date-fns";
import urls from "../../constants/Urls";
import axios from "axios";

const TraineeApplications = () => {
  const [loading, setLoading] = useState(false);
  const [trainings, setTrainings] = useState([]);
  const [price, setPrice] = useState("");
  const [courseUuid, setCourseUuid] = useState("");
  const [range, setRange] = useState({
    startDate: new Date("2000-01-01"),
    endDate: add(new Date(), { months: 4 }),
  });

  const getPrice = async (getId) => {
    setCourseUuid(getId);
    console.log(getId);
    await axios
      .get(urls.api + "courses/" + courseUuid)
      .then((result) => {
        console.log(result.data.data);
        setPrice(result.data.data.cost);
        console.log(price);
      })
      .catch((error) => {
        console.log("an error has occured " + error);
      });
  };

  const allTrainings = async () => {
    const calendars = urls.api + "calendar/course-calendar/";
    await axios
      .post(calendars, range)
      .then((result) => {
        const trainingData = result.data.data;
        console.log(result.data.data);
        setTrainings(trainingData);
        // setLoading(false)
        console.log(trainings);
      })
      .catch((error) => {
        console.error(error);
      });
  };

  const sortedCourses = [...trainings].sort((a, b) => {
    const dateA = new Date(a.startDate);
    const dateB = new Date(b.startDate);
    return dateA - dateB;
  });
  // setLoading(true);
  useEffect(() => {
    allTrainings();
  }, [trainings, setTrainings]);
  return (
    <div class="content container-fluid" style={{ paddingTop: "10rem" }}>
      {/* <PageTitle title="Applications" /> */}

      <div class="card mx-3 mb-5">
        <div class="card-body">
          {/* <div style={{ display: loading ? `block` : `none` }}>
            <Spinner />
          </div> */}
          <div className="row">
            <div className="table-responsive">
              <table className="table">
                <thead>
                  <tr>
                    {/* <th>S/N</th> */}
                    <th>Training Course Title</th>
                    <th>Training Date </th>
                    <th>Application Close Date</th>
                    <th>Cost (NGN)</th>
                    <th>Days Left</th>
                  </tr>
                </thead>
                <tbody>
                  {sortedCourses.length ? (
                    sortedCourses.map((x) => {
                      return (
                        <tr>
                          {/* <td className="py-3">{0}</td> */}
                          <td className="py-3">{x.courseTitle}</td>
                          <td className="py-3">{x.startDate}</td>
                          <td className="py-3">{x.endDate}</td>
                          <td className="py-3">
                            <i
                              style={{ fontStyle: "bold" }}
                              onClick={() => {
                                getPrice(x.courseUuid);
                              }}
                              className="fa fa-eye mx-4"
                            >
                              {x.courseUuid === courseUuid ? (
                                <>{price}</>
                              ) : (
                                <></>
                              )}
                            </i>
                          </td>
                          <td className="py-3">
                            {Math.ceil(
                              Math.abs(
                                new Date(x.endDate) - new Date(x.startDate)
                              ) / 86400000
                            ) + " days"}
                          </td>
                        </tr>
                      );
                    })
                  ) : (
                    <></>
                  )}
                </tbody>
              </table>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default TraineeApplications;
