import React, { Component, useEffect, useState } from "react";
import { Link, useParams } from "react-router-dom";
import Images from "../../constants/Images";
import TopHeader from "../TopHeader";
import "../../constants/custom.css";
import SupportRightSideBar from "../supports/SupportRightSideBar";

const ProgrammesAndCourses = () => {
  let postsList = [
    {
      id: 1,
      title: "Technical Courses",
      postImage: Images.technical_under_program,
      url: "/technical_courses",
    },
    {
      id: 2,
      title: "Non-Technical Courses",
      postImage: Images.non_technical_under_program,
      url: "/non_technical_courses",
    },
    {
      id: 3,
      title: "NGSDP Programme",
      postImage: Images.ngsdp_under_program,
      url: "/ngsdp",
    },
    {
      id: 4,
      title: "NAPSAS Programme",
      postImage: Images.ntsap_under_program,
      url: "/ntsap",
    },
  ];

  const [title, setTitle] = useState("Programmes And Courses");
  const [loading, setLoading] = useState(false);
  const [questions, setQuestions] = useState([]);

  const [activeRow, setActiveRow] = useState(1);
  const [expandAll, setExpandAll] = useState(false);
  const [collapseAll, setCollapseAll] = useState(false);

  const [search, setSearch] = useState(null);
  const searchFaq = (y) => {
    setLoading(true);
    var event = postsList.filter((x) => x.body.includes(y));
    setQuestions(event);
    setLoading(false);
  };

  const openQuestion = (x) => {
    setCollapseAll(false);
    setExpandAll(false);
    setActiveRow(x);
  };

  const expandAllQuestions = () => {
    setCollapseAll(false);
    setExpandAll(true);
  };

  const collapseAllQuestions = () => {
    setCollapseAll(true);
    setExpandAll(false);
  };

  return (
    <div class="auto-container">
      <div className="row mt-5">
        <TopHeader title={title} />

        <div class="container mt-5">
          <div className="row">
            <div class="content-column col-lg-8 cl-md-12 col-sm-12">
              <div className="row">
                <div className="row text-dark">
                  {postsList.map((x) => (
                    <div className="col-md-4">
                      <div className="m-3">
                        <div
                          class="inner-box wow fadeInLeft animated"
                          data-wow-delay="0ms"
                        >
                          <div class="image">
                            <Link to={x.url}>
                              <img src={x.postImage} alt="" />
                            </Link>
                            <Link
                              to={x.url}
                              class="EnrollBtn btn btn-success btn-sm"
                            >
                              View
                            </Link>
                          </div>
                          <div class="lower-content">
                            <h4>
                              <a href="#">{x.title}</a>
                            </h4>
                          </div>
                        </div>
                      </div>
                    </div>
                  ))}
                </div>
              </div>
            </div>

            <SupportRightSideBar />
          </div>
        </div>
      </div>
    </div>
  );
};

export default ProgrammesAndCourses;
