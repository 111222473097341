import { useEffect } from "react";


const TraineeDashbaord = () =>{

    useEffect(()=>{
       // alert("Hello world")
    }, [] )

    
    return (
    <></>
    
    )
}

export default TraineeDashbaord;