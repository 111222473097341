import React, { Component, useEffect, useState } from "react";
import { Link, useParams } from "react-router-dom";
import Images from "../../constants/Images";
import TopHeader from "../TopHeader";
import "../../constants/custom.css";
import axios from "axios";
import urls from "../../constants/Urls";

const ViewPost = () => {
  const { id } = useParams();

  const [event, setEvents] = useState([]);
  const [postTitle, setPostTitle] = useState(null);

  useEffect(() => {
    (async () => {
      const announcements = urls.api + "announcements/" + id;
      await axios.get(announcements).then((result) => {
        const events = result.data.data;
        setEvents(events);
        console.log(events);
      });
    })();
  }, []);

  return (
    <div class="auto-container">
      <div className="row mt-5">
        <TopHeader title={postTitle} />

        <div class="container mt-5">
          <div className="row">
            <div className="col-12 text-dark mb-5">
              <h4>
                <b>{event.title}</b>
              </h4>
            </div>
            <div className="col-sm-12 col-md-4 col-lg-4 news-img">
              <img
                className="PostThumbnail w-100 rounded my-3"
                src={event.banner}
                style={{
                  height: "100%",
                  width: "100%",
                }}
              />
            </div>
            <div className="col-sm-12 col-md-8 col-lg-8 mt-3">
              <p>{event.body}</p>
              <p>
                <b>Created At:</b> {event.createdAt}
              </p>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ViewPost;
