import { Outlet, Link } from "react-router-dom";
import TraineeFooter from "./Footer";
import TraineeHeader from "./Header";

const TraineeMainLayout = () => {   

    return (<div class="layout-fluid">
             <div class="preloader">
            <div class="sk-chase">
                <div class="sk-chase-dot"></div>
                <div class="sk-chase-dot"></div>
                <div class="sk-chase-dot"></div>
                <div class="sk-chase-dot"></div>
                <div class="sk-chase-dot"></div>
                <div class="sk-chase-dot"></div>
            </div>
            </div>
            <div class="mdk-header-layout js-mdk-header-layout">
 	<TraineeHeader/>
        <Outlet/>
        <TraineeFooter/>
        </div>
    </div>
    );
}

export default TraineeMainLayout;