import { Link } from "react-router-dom";

const TopHeader = (props) => {   
    
    document.title = props.title; 
    return(
        
        <div className="container">
        <section className="" style={{marginTop: 80}}>
        <div class="auto-container">
        <div class="row clearfix">
                <h6 className='text-dark text-center'><b>{props.title}</b></h6>
            </div>
            </div>
            </section>
            </div>
    )
    
    }
    
    export default TopHeader;