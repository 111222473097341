const macbook_half = require("./Images/macbook_half.png");
const pc_user = require("./Images/pc_user.jpg");
const engineer = require("./Images/engineer.png");
const technical_trainer = require("./Images/technical_trainer.png");
const prog_1 = require("./Images/prog_1.png");
const prog_2 = require("./Images/prog_2.png");
const prog_3 = require("./Images/prog_3.png");
const prog_4 = require("./Images/prog_4.png");
const prog_5 = require("./Images/prog_5.png");

const tmis_1 = require("./Images//NAPTIN WEBSITE PICTURES/nine.png");
const tmis_2 = require("./Images/TMISSLIDER/Sliderhome2.png");
const tmis_two = require("./Images/tmisslider2.jpg");
const tmis_3 = require("./Images/TMISSLIDER/Sliderhome3.jpg");
const img_two = require("./Images/NAPTIN WEBSITE PICTURES/two.png");
const img_three = require("./Images/NAPTIN WEBSITE PICTURES/one.png");
const img_four = require("./Images/NAPTIN WEBSITE PICTURES/four.png");
const tmis_4 = require("./Images/TMISSLIDER/Sliderhome4.jpg");
const tmis_5 = require("./Images/TMISSLIDER/Sliderhome5.jpg");
const ngsdp = require("./Images/ngsdppage.jpg");
const banner4 = require("./Images/banner4.jpeg");
const banner6 = require("./Images/banner6.jpg");
const banner3 = require("./Images/banner3.jpg");
const banner5 = require("./Images/banner5.jpg");

const ngsdp_banner = require("./Images/ngsdppageafterbanner.jpg");
const line_maintenance = require("./Images/linemaintenance.jpg");
const ntsap = require("./Images/ntsappage.jpg");
const non_technical_1 = require("./Images/TMISSLIDER/Non-Technical/nontechnicalcoursebanner.jpg");
const technical_1 = require("./Images/TMISSLIDER/technicalCourse/technicalcoursebanner.jpg");
const non_technical_under_program = require("./Images/TMISSLIDER/nontechtrainingunderprograms.jpg");
const technical_under_program = require("./Images/TMISSLIDER/techtrainingunderprograms.jpg");
const ngsdp_under_program = require("./Images/TMISSLIDER/ngsdpunderprograms.jpg");
const ntsap_under_program = require("./Images/TMISSLIDER/ntsappunderprograms.jpg");

export default {
  macbook_half,
  pc_user,
  engineer,
  technical_trainer,
  prog_1,
  banner4,
  banner6,
  prog_2,
  img_four,
  img_three,
  img_two,
  prog_3,
  prog_4,
  banner3,
  banner5,
  prog_5,
  tmis_1,
  tmis_2,
  tmis_3,
  tmis_4,
  tmis_5,
  ngsdp,
  ngsdp_banner,
  line_maintenance,
  ntsap,
  non_technical_1,
  technical_1,
  non_technical_under_program,
  technical_under_program,
  ngsdp_under_program,
  ntsap_under_program,
  tmis_two,
};
