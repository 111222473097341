import React, { Component, useState, useEffect } from 'react';
import { Link, useLocation, useParams } from "react-router-dom";
import Alert from '../../constants/Alert';
import ErrorMessages from '../../constants/ErrorMessages';
import Spinner from '../../constants/Spinner';
import Urls from '../../constants/Urls';

const LogOut = () => {   

    useEffect( () => {
        localStorage.removeItem("token")
               if (localStorage.getItem("token") === null) {
            window.location.replace("/")
        }
        
        }, [])

    

    return (
        <div className='row'>

            <div className='bg-success col-md-12'>
        <div class="py-32pt">
        <div class="container d-flex flex-column flex-md-row align-items-center mt-5">
            {/* <i className='fa fa-graduation-cap fa-3x text-white'></i> */}
            <div class="flex mb-32pt mb-md-0">
                <p class="lead measure-lead text-white-50">Logging Out...</p>
            </div>
        </div>
        </div>
    </div>

    </div>
    );
}

export default LogOut;