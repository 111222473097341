import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import TopHeader from "../TopHeader";
import "../../constants/custom.css";
import axios from "axios";
import urls from "../../constants/Urls";

const TrainingCenters = () => {
  document.title = "Training Centres";
  const [events, setEvents] = useState([]);
  const getCenters = async () => {
    const api = urls.api + "regional-training-centers";
    axios
      .get(api)
      .then((result) => {
        const events = result.data.data;
        console.log(result);
        setEvents(events);
      })
      .catch((error) => {
        console.log(error);
      });
  };
  useEffect(() => {
    getCenters();
  }, []);

  let title = (document.title = "REGIONAL TRAINING CENTERS (RTC)");

  return (
    <div class="auto-container">
      <div class="container mt-5">
        <div className="row mt-5">
          <div className="mt-5">
            <TopHeader title={title} />
          </div>
        </div>
        <div class="content-column col-12">
          <div
            className="row g-5"
            style={{ marginTop: "5rem", marginBottom: "5rem" }}
          >
            {events.map((x) => (
              <div className="col-md-4 mb-5">
                <div className="text-dark shadow-sm rounded p-4">
                  <Link to={"/" + x.uuid + "/post"} style={{ color: "#000" }}>
                    <h5
                      className="mb-3 text-dark py-2 text-center rounded"
                      style={{ background: "#9dcb93" }}
                    >
                      <strong>{x.name}</strong>
                    </h5>
                    <span>
                      <b>Email:</b> {x.email}
                    </span>{" "}
                    <br />
                    <span>
                      <b>Phone No:</b> {x.phoneNumber}
                    </span>
                    <br />
                    <span>
                      <b>Address:</b> {x.address}
                    </span>
                    <br />
                  </Link>
                </div>
              </div>
            ))}
          </div>
        </div>
      </div>
    </div>
  );
};

export default TrainingCenters;
