import React, { Component } from 'react';

const Spinner = () => {   

    return (
        <div class="spinner-border" role="status">
        <span class="visually-hidden">Loading...</span>
      </div>
    );
}

export default Spinner;