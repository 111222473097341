import React, { Component } from "react";
import { Link } from "react-router-dom";

import Images from "../../constants/Images";

const Ntsap = () => {
  document.title = "NAPSAS";
  let programmes = [
    {
      id: 1,
      title: "Line Maintenance",
      image: Images.line_maintenance,
      url: "",
    },
    {
      id: 1,
      title: "Distribution Substation Operators(DSO)",
      image: Images.prog_2,
      url: "",
    },
    { id: 1, title: "Cable Jointing", image: Images.prog_3, url: "" },
    {
      id: 1,
      title: "Electrical Maintenance & Fitters",
      image: Images.prog_4,
      url: "",
    },
    {
      id: 1,
      title: "Electricity Marketing & Meter Management",
      image: Images.prog_5,
      url: "",
    },
  ];

  return (
    <>
      <section
        class="banner-section-four"
        style={{ backgroundColor: "#EFF8FA" }}
      >
        <div class="auto-container">
          <div class="row clearfix">
            <div class="content-column col-lg-6 cl-md-12 col-sm-12">
              <div class="inner-column">
                <h2 className="text-dark">
                  NTSAP: NAPTIN Technical Skills Acquisition Programme
                </h2>
                <h5 className="text-dark mt-2">
                  The NAPTIN Technical Skills Acquisition Programme (NTSAP) is
                  an initiative of the Federal and State government through the
                  Ministry of Power with the aim of bridging the wide gap in the
                  low end skill cadre of the Power sector.
                </h5>
              </div>
            </div>

            <div class="image-column col-lg-6 cl-md-12 col-sm-12">
              <div class="inner-column">
                <div class="color-layer"></div>
                <div
                  class="icon-layer-four"
                  style={{
                    backgroundImage: `url(/assets/images2/background/pattern-7.png)`,
                  }}
                ></div>
                <div
                  class="icon-layer-five"
                  style={{
                    backgroundImage: `url(/assets/images2/icons/icon-12.png)`,
                  }}
                ></div>
                <div
                  class="icon-layer-six"
                  style={{
                    backgroundImage: `url(/assets/images2/icons/icon-3.png)`,
                  }}
                ></div>
                <div
                  class="icon-layer-seven"
                  style={{
                    backgroundImage: `url(/assets/images2/icons/icon-12.png)`,
                  }}
                ></div>
                <div class="image">
                  <img
                    src={Images.img_four}
                    style={{ width: `100%`, height: `350px` }}
                    alt=""
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>

      <div class="row">
        <div class="col-lg-12 col-md-12 col-sm-12">
          <div class="mt-5 m-4">
            Having been considered for admission into the NAPSAS programme being
            sponsored by Federal and State government, you are advised to
            register online and apply for the programme by following the
            procedures outlined below carefully to avoid unnecessary mix-ups.
          </div>
          <div class="mt-5 m-4">
            <h6>
              <b>Procedure for Online Registration</b>
            </h6>
            <br />
            <div className="row">
              <div class="col-lg-6 col-md-12 col-sm-12">
                <br />
                <div className="card">
                  <div className="col-md-12">
                    <br />
                    <span>NEW USERS</span>
                    <br />
                    <p>
                      1. Click on the 'Create Account/SignUp' link to SignUp
                      Now. <a href="/signup">Create Account/SignUp</a>
                      <br />
                      <br />
                      2. After creating an account on the portal, Sign In to
                      fill the Application Form provided
                      <br />
                      <br />
                      3. Click on the Training Advert menu to see the list of
                      courses advertised, read the details for any of your
                      choice of course before applying in order to know the
                      course you are eligible for based on your qualification.
                    </p>
                  </div>
                </div>
              </div>
              <div class="col-lg-6 col-md-12 col-sm-12">
                <br />
                <div className="card">
                  <div className="col-md-12">
                    <br />
                    <span>EXISTING USERS</span>
                    <br />
                    <p>
                      1. Click on the 'Login' link to sign In.{" "}
                      <a href="/login">Login Now</a>
                      <br />
                      <br />
                      2. Click on the Training Advert menu to see the list of
                      courses advertised, read the details for any of your
                      choice of course before applying in order to know the
                      course you are eligible for based on your qualification.
                    </p>
                    <br />
                    <br />
                  </div>
                </div>
              </div>

              <div class="col-lg-6 col-md-12 col-sm-12">
                <br />
                <div className="card bg-info text-white">
                  <div className="col-md-12">
                    <p>
                      {" "}
                      <i className="fa fa-info-circle"></i> Please note that the
                      sponsors of this programme, Federal and State Governments
                      of Nigeria, have FULLY PAID for your participation, and as
                      such you will NOT be required to make any payment. In
                      addition, the FGN, through the Ministry of Power would pay
                      you a MONTHLY STIPEND throughout the 6 months duration of
                      the programme.
                    </p>
                  </div>
                </div>
              </div>

              <div class="col-lg-6 col-md-12 col-sm-12">
                <br />
                <div className="mt-4">
                  NOTE: Ensure you select only ONE of the Five (5) NAPSAS
                  Courses advertised, when applying. Note that Multiple
                  Applications will be disqualified.
                </div>
              </div>
            </div>
          </div>
          {/* <div class="mt-5 m-4">
            <h6>
              <b>Registration Period</b>
            </h6>
            <br />
            All registration activities would START on Monday, 19th January
            2015, and END on Friday, 30th January 2015 by 12:00 Midnight. Please
            ensure you complete your registration within the time frame provided
            (19th – 30th January 2015).
          </div> */}
          <div class="mt-5 m-4">
            <div className="row">
              <div className="col-lg-6 col-md-12 col-sm-12 text-left">
                <h6>
                  <b>Training Programs Under NAPSAS</b>
                </h6>
              </div>
              <div className="col-lg-6 col-md-12 col-sm-12 text-right">
                <b>
                  Course Fee: <span className="text-success">Free</span>
                </b>
              </div>
            </div>

            <div className="row">
              {programmes.map((x) => (
                <div className="col-md-4">
                  <div className="m-3">
                    <img
                      src={x.image}
                      style={{ width: `100%`, height: `300px` }}
                    />
                    <div>
                      <b>{x.title}</b>
                    </div>
                  </div>
                </div>
              ))}
            </div>
          </div>
          <div class="col-12 mt-5 m-4">
            <h6>
              <b>Inquiries & Clarification</b>
            </h6>
            <br />
            If you have any difficulty in the registration process, please call
            any of these numbers for assistance: 08060844971, 08033543304 and
            08092950420 between 8:00 am to 5:00 pm, Monday to Friday OR send an
            email to: support@naptinportal.com
          </div>
        </div>

        {/*<div class="col-lg-4 col-md-12 col-sm-12">
                            <div class="col-12 mt-5 m-4">
                                Right Contents
                                </div>
                            </div>*/}
      </div>
    </>
  );
};

export default Ntsap;
