import React, { Component } from 'react';

const books = require("./icons/books.png");
const books_bg = require("./icons/books_bg.png");

const graduate = require("./icons/graduate.png");
const graduate_bg = require("./icons/graduate_bg.png");

const trainer = require("./icons/trainer.png");
const trainer_bg = require("./icons/trainer_bg.png");

const calendar = require("./icons/calendar.png");
const calendar_bg = require("./icons/calendar_bg.png");

const badge = require("./icons/badge.png");
const badge_bg = require("./icons/badge_bg.png");

const building = require("./icons/building.png");
const building_bg = require("./icons/building_bg.png");

const love = require("./icons/love.png");

const price_icon  = require("./icons/price-icon-4.png");


export default {books, 
    books_bg, graduate, 
    graduate_bg, trainer, 
    trainer_bg, 
    calendar, calendar_bg, 
    badge, 
    badge_bg, building, 
    building_bg, love, price_icon};