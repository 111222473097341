import React, { Component, useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import Images from '../../constants/Images';
import TopHeader from '../TopHeader';
import '../../constants/custom.css' 
import axios from 'axios'
import urls from '../../constants/Urls';


const EventsNews = () => {   

    const [title, setTitle] = useState("Announcements");
    const [subTitle, setSubTitle] = useState("What’s happening at NAPTIN ");

    const [events, setEvents] = useState([]);


    useEffect(() => {
        (async() => {
            const announcements = urls.api + 'announcements/'
            await axios.get(announcements)
            .then((result) => {
                const events = result.data.data.announcements
                setEvents(events)
                console.log(events)
            })
        })();
    }, []);

    // let event = postsList 
    // event = postsList.filter(x => x.type == "Event")

    return (
        <div class="auto-container">
           
            <div className='row mt-5'>
            <TopHeader title={title}/>
            
        <div class="container mt-5">
            <div className='row'>
            <div class="content-column col-lg-8 col-md-12 col-sm-12">
            <div className='row mb-5'>
            {events.length != 0 ?
            <>
                {events.map(x =>
                    <div className='col-md-4 text-dark'>
                    <Link to={'/'+x.uuid+'/post'} style={{color: '#000'}}>
                    <img className='PostThumbnail' src={x.banner} /><br/>
                    <h6 className='mt-3 text-dark'><strong>{x.title}</strong></h6>
                    <p className='text-secondary'>{x.body.length > 100 ? x.body.slice(0, 100) + "..." :  <>{x.body}</>}</p>
                    <p>
                    <b>{x.createdAt}</b> <br/>
                    <i>{x.updatedAt}</i>

                    </p>
                    </Link>
                    </div>
                )}
            </>
            :
                <h5>"No Announcements"</h5>
            }
            </div>

                {/* <div className='col-lg-12 col-md-12 col-sm-12 text-center mb-5'>
                    <Link to={'#'} className='btn btn-sm btn-success'>Load More...</Link>
                </div> */}
        </div>

               
                <div class="content-column col-lg-4 col-md-12 col-sm-12">
                {events.length != 0 ?
                <>
                <h6 className='text-dark text-center mb-2'><b>Recents</b></h6>
                <div className='row'>
                    {events.map(x =>
                    <div className='col-md-12 text-dark'>
                    <Link to={'/'+x.uuid+'/post'} style={{color: '#000'}}>
                        <div className='row m-1'>
                        <div className='col-md-6'><img className='PostThumbnailSide' src={x.banner} /></div>
                        <div className='col-md-6'><h6 className='mt-3 text-dark'><strong>{x.title}</strong></h6>
                        <p>
                        <b>{x.createdAt}</b> <br/>
                        <i>{x.updatedAt}</i>
                        </p>
                        </div>
                        </div>    
                        </Link>
                        </div>
                    )}
                </div>
                </>
            :
                <div></div>
            }
                </div>
                </div>
                
    </div>
    
    </div>
    </div>
    );
}

export default EventsNews;