import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import Icons from "../constants/Icons";
import "../constants/custom.css";
import Images from "../constants/Images";
import axios from "axios";
import urls from "../constants/Urls";

const HomeNews = () => {
  const [title, setTitle] = useState("News & Events");
  const [subTitle, setSubTitle] = useState("What’s happening at NAPTIN ");
  const [events, setEvents] = useState([]);

  useEffect(() => {
    (async () => {
      const announcements = urls.api + "announcements/";
      await axios.get(announcements).then((result) => {
        const events = result.data.data.announcements;
        setEvents(events);
      });
    })();
  }, []);

  return (
    <div className="container">
      <div className="row mt-5">
        <div className="col-lg-6 col-md-12 col-sm-12 text-left">
          {subTitle}
          <h6 className="text-dark">
            <b>{title}</b>
          </h6>
        </div>
        {events.length != 0 ? (
          <>
            <div className="col-lg-6 col-md-12 col-sm-12 text-right">
              <Link to={"/news"} className="btn btn-sm btn-success">
                All News & Events
              </Link>
            </div>
            <div class="container mt-5">
              <div className="row">
                {events.slice(0, 3).map((x) => (
                  <div className="col-md-4 text-dark">
                    <Link to={x.uuid + "/post"} style={{ color: "#000" }}>
                      <img className="PostThumbnail" src={x.banner} />
                      <br />
                      <h6 className="mt-3 text-dark">
                        <strong>{x.title}</strong>
                      </h6>
                      <p className="text-secondary">
                        {x.body.length > 100 ? (
                          x.body.slice(0, 100) + "..."
                        ) : (
                          <>{x.body}</>
                        )}
                      </p>

                      <div className="col-lg-12 col-md-12 col-sm-12 text-left mb-5 mt-3">
                        <Link
                          to={x.uuid + "/post"}
                          className="btn btn-sm btn-success"
                        >
                          Read More
                        </Link>
                      </div>
                    </Link>
                  </div>
                ))}
              </div>
            </div>
          </>
        ) : (
          <div className="col-12 py-5">
            <h5 className="text-left">"No Announcements"</h5>
          </div>
        )}
      </div>
    </div>
  );
};

export default HomeNews;
