import React, { Component, useEffect, useState } from "react";
import Images from "../constants/Images";
import "../App.css";
import AliceCarousel from "react-alice-carousel";
import "react-alice-carousel/lib/alice-carousel.css";

const Slider = () => {
  let sliderContents = [
    { id: 1, image: Images.tmis_1 },
    { id: 2, image: Images.tmis_2 },
    { id: 3, image: Images.tmis_3 },
    { id: 4, image: Images.tmis_4 },
  ];
  const [showImage, setShowImage] = useState(1);
  const [count, setCount] = useState(0);

  const changeSlide = (x) => {
    setShowImage(x);
  };

  useEffect(() => {
    if (showImage < 1) setShowImage(1);
    setInterval(() => {
      if (showImage > 3) setShowImage(0);
      if (showImage < 4) setShowImage(showImage + 1);
      //setShowImage(prevCount => prevCount + 1);
    }, 6000);
  }, []);

  return (
    <div className="row small-sm mb-2 ">
      <AliceCarousel
        autoPlay
        autoPlayInterval={2000}
        disableDotsControls // Removes the dots
        disableButtonsControls
        dotsInImages
        infinite="true"
        className="carousel-container  alice-carousel "
      >
        <img className="sliderimage w-100" src={Images.tmis_1} alt="" />

        <img className="sliderimage w-100" src={Images.banner4} alt="" />

        <img className="sliderimage w-100" src={Images.banner6} alt="" />

        <img className="sliderimage w-100" src={Images.banner3} alt="" />
        <img className="sliderimage w-100" src={Images.banner5} alt="" />
      </AliceCarousel>
    </div>
  );
};

export default Slider;
