import React, { Component, useState } from "react";
import { Link } from "react-router-dom";
const Footer = () => {
  const [thisYear, setThisYear] = useState(new Date().getFullYear());
  const [about, setAbout] = useState(
    "NAPTIN has embarked on the development of world-class training programmes, which will require many international experts working alongside Nigerian experts."
  );
  const phone = "+234-706-777-7559";
  const email = " support@naptinportal.com";

  let bottonLinks = [
    { id: 1, title: "Technical Courses", url: "/technical_courses" },
    { id: 2, title: "Non-Technical Courses", url: "non_technical_courses" },
    { id: 3, title: "NGSDP", url: "/ngsdp" },
    { id: 4, title: "NTSAP", url: "/ntsap" },
  ];
  return (
    <footer class="footer-style-two">
      <div class="auto-container">
        <div class="upper-box">
          <div class="clearfix">
            <div class="pull-left">
              <div class="logo">
                <a href="/">
                  <img src="/assets/images/logo.png" alt="" />
                </a>
              </div>
            </div>
          </div>
        </div>

        <div class="widgets-section">
          <div class="row clearfix">
            <div class="big-column col-lg-6 col-md-12 col-sm-12">
              <div class="row clearfix">{about}</div>
              <div class="row clearfix mb-3"></div>
              <div class="row clearfix">
                {bottonLinks.map((x) => (
                  <a href={x.url} className="text-secondary">
                    {x.title} &nbsp; &nbsp; &nbsp;
                  </a>
                ))}
              </div>
            </div>

            <div class="big-column col-lg-6 col-md-12 col-sm-12">
              <div class="clearfix"></div>

              <div class="row clearfix mb-3"></div>
              <div class="clearfix">
                <div class="pull-right">
                  <span class="phone">{phone}</span> &nbsp; &nbsp;
                  <span class="phone">{email}</span>
                  <br></br>
                  <span class="phone">{"+234-810-641-9581"}</span> &nbsp; &nbsp;
                  <span class="phone">{"servicom"}</span>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div class="footer-bottom">
          <div class="upper-box">
            <div class="clearfix">
              <div class="pull-left">
                <ul class="social-box">
                  <li>
                    <a
                      href="https://www.facebook.com/Naptin-National-Power-Training-Institute-of-Nigeria-1064794980229653"
                      class="fa fa-facebook-f"
                    ></a>
                  </li>
                  <li>
                    <a
                      href="https://twitter.com/NAPTIN_Nigeria"
                      class="fa fa-twitter"
                    ></a>
                  </li>
                  <li>
                    <a
                      href="https://www.linkedin.com/company/naptin"
                      class="fa fa-linkedin"
                    ></a>
                  </li>
                  <li>
                    <a
                      href="https://www.youtube.com/channel/UC6B6cTd9FVk4OiykJrJIYUA"
                      class="fa fa-youtube-play"
                    ></a>
                  </li>
                  <li>
                    <a
                      href="https://www.instagram.com/naptin_nigeria/"
                      class="fa fa-instagram"
                    ></a>
                  </li>
                </ul>
              </div>
              <br />
              <div class="pull-right">
                <div class="phone">Terms and Conditions</div>
                <div class="phone" style={{ marginTop: "-10px" }}>
                  <Link to="privacy-policy" className="phone">
                    {" "}
                    <p style={{ fontSize: "18px", marginTop: "-10px" }}>
                      Privacy Policy
                    </p>
                  </Link>
                </div>
              </div>
              <div class="phone-box my-5">
                <div class="col text-left">
                  <img
                    className="mr-2"
                    style={{ width: `150px`, height: `50px` }}
                    src="/assets/images/apple_store.png"
                  />
                  <a href="https://play.google.com/store/apps/details?id=com.naptin.naptin_tmis_app">
                    {" "}
                    <img
                      style={{ width: `150px`, height: `65px` }}
                      src="/assets/images/google_store.png"
                    />
                  </a>
                </div>
              </div>
            </div>
          </div>

          <div class="copyright">Copyright {thisYear}, All Right Reserved</div>
        </div>
      </div>
    </footer>
  );
};

export default Footer;
