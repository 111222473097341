import React, { Component, useEffect, useState } from "react";
import { Link, useParams } from "react-router-dom";
import Images from "../../constants/Images";
import TopHeader from "../TopHeader";
import "../../constants/custom.css";
import Spinner from "../../constants/Spinner";
import Alert from "../../constants/Alert";

const SupportRightSideBar = () => {
  const [loading, setLoading] = useState(false);
  const [submitQuestion, setSubmitQuestion] = useState(false);
  const [showAlert, setShowAlert] = useState(false);
  const [alertType, setAlertType] = useState(null);
  const [alertMessage, setAlertMessage] = useState(null);
  const [alertTitle, setAlertTitle] = useState(null);

  const [phone, setPhone] = useState(null);
  const [email, setEmail] = useState(null);
  const [subject, setSubject] = useState(null);
  const [message, setMessage] = useState(null);
  const [fullName, setFullName] = useState(null);

  const askQuestion = () => {
    setSubmitQuestion(true);
  };

  const submitquestion = () => {
    setShowAlert(false);
    setLoading(true);
    if (message == null) {
      setAlertMessage("Question can not be empty");
      setAlertTitle("Error!");
      setAlertType("danger");
      setShowAlert(true);
    }
    if (fullName == null) {
      setAlertMessage("Full name can not be empty");
      setAlertTitle("Error!");
      setAlertType("danger");
      setShowAlert(true);
    } else {
      setAlertMessage("Question sent");
      setAlertTitle("Successful!");
      setAlertType("success");
      setShowAlert(true);
    }
    setLoading(false);
  };

  useEffect(() => {}, []);

  return (
    <div class="content-column col-lg-4 cl-md-12 col-sm-12">
      <div className="row">
        {!submitQuestion ? (
          <div className="col-md-12 mb-5">
            <button
              onClick={() => askQuestion()}
              className="btn btn-sm btn-success"
            >
              <i className="fa fa-question-circle"></i> Submit A Question
            </button>
          </div>
        ) : (
          <div className="col-md-12 mb-5">
            <div className="col mt-2">
              <label>Full Name</label>
              <input
                className="form-control"
                value={fullName}
                onChange={(x) => setFullName(x.target.value)}
                type={"text"}
              />
            </div>
            <div className="col mt-2">
              <label>Phone</label>
              <input
                className="form-control"
                value={phone}
                onChange={(x) => setPhone(x.target.value)}
                type={"tel"}
              />
            </div>
            <div className="col mt-2">
              <label>Email</label>
              <input
                className="form-control"
                value={email}
                onChange={(x) => setEmail(x.target.value)}
                type={"email"}
              />
            </div>
            <div className="col mt-2">
              <label>Question</label>
              <textarea
                className="form-control"
                onChange={(x) => setMessage(x.target.value)}
              >
                {message}
              </textarea>
            </div>

            {showAlert ? (
              <Alert
                type={alertType}
                title={alertTitle}
                message={alertMessage}
              />
            ) : (
              ""
            )}

            <div className="col mt-2">
              {!loading ? (
                <button
                  onClick={() => submitquestion()}
                  className="btn btn-sm btn-success"
                >
                  <i className="fa fa-check-circle"></i> Submit Question
                </button>
              ) : (
                <Spinner />
              )}
            </div>
          </div>
        )}
        <div className="col-md-12">
          <b>Note</b>
          <br />
          For further inquiries, please contact our Support Team via email on{" "}
          <span className="text-success">support@naptinportal.com</span>
        </div>
      </div>
    </div>
  );
};

export default SupportRightSideBar;
