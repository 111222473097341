import { Link } from "react-router-dom";

const Alert = (props) => {   
    
    return(
        <div className='col mt-2'>
        <div class={"alert alert-"+props.type+" alert-dismissible fade show"} role="alert">
  <strong>{props.title}</strong> {props.message}
  <button type="button" class="close" data-dismiss="alert" aria-label="Close">
    <span aria-hidden="true">&times;</span>
  </button>
</div>
</div>
    )
    
    }
    
    export default Alert;